import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  PRIMARY_COLOR_DarkNavyBlue,
} from "../../../constants/Colors";
import { Typography } from "@mui/material";
import { AdminsContext } from "../../../contexts/AdminsContext";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import LoadingButton from "../../../general_components/LoadingButton";
import CustomTextField from "../../../general_components/CustomTextField";
import IndexServices from "../../../services/IndexServices";

export default function AddAdmin({ open, setOpen }) {
  const adminsContext = React.useContext(AdminsContext);
  const {
    name, setName, email, setEmail, password, setPassword,
    adminType, setAdminType, handleAddData, setAdmins,
    handleEmpty, loading, setLoading, admins
  } = adminsContext;


  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password.length < 5) {
      alert("Password must be at least 5 characters.");
      return;
    }
    setLoading(true);
    setOpen(false);
    setLoading(false);
    const data = {
      name: name, email: email, role: adminType, password: password
    }
    await IndexServices.addAdmin(data)
      .then((response) => {
        if (response.data.success) {
          setAdmins([...admins, response?.data?.data]);
          setOpen(false);
        } else {
          alert(response.data.error);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        alert("Something went wrong!");
      });
    handleEmpty()
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>
          <Typography
            variant={"h6"}
            style={{ color: PRIMARY_COLOR_DarkNavyBlue, fontWeight: "600" }}
          >
            Add Admin
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: 400, paddingTop: 10 }}>
          <form onSubmit={onSubmit}>
            <CustomTextField
                labelName={"Role"}
                required
                style={{ marginBottom: 15 }}
                select
                value={adminType}
                onValueChange={setAdminType}
            >
              {
                ["Super_Admin", "Admin"].map((value, index) => {
                  return (
                      <MenuItem key={`${index}AddAdmin`} value={value}>
                        {value}
                      </MenuItem>
                  );
                })
              }
            </CustomTextField>
            <CustomTextField
              autoFocus={true}
              value={name}
              onValueChange={setName}
              required
              labelName={"Name"}
              style={{ marginBottom: 15 }}
            />
            <CustomTextField
              labelName={"Email"}
              value={email}
              onValueChange={setEmail}
              type={"email"}
              required
              style={{ marginBottom: 15 }}
            />
            <CustomTextField
              labelName={"Password"}
              value={password}
              onValueChange={setPassword}
              type={"password"}
              required
              style={{ marginBottom: 30 }}
            />
            <LoadingButton
              type={"submit"}
              loading={loading}
              text={"Add Admin"}
            />
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
