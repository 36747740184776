import React from 'react';
import CustomTextfield from "../../../general_components/CustomTextField";
import LoadingButton from "../../../general_components/LoadingButton";
import {makeStyles} from "@mui/styles";
import Editor from "./EmailEditor";

const useStyles = makeStyles({
    inputContainer: {
        padding: '10px',
        flexDirection: 'column',
        minHeight: '100%',
        maxHeight: '100%',
    },
    buttonContainer: {
        marginTop: 25
    }
}) 


export default function Body({mailbox,loading, email, setEmail, message, setMessage, subject, setSubject})
{
    const classes = useStyles();

    return (
        <div className={classes.inputContainer}>
            {
                !mailbox ? null :
                <CustomTextfield labelName={'To'} type={'to'} required
                      value={email} onValueChange={setEmail} disable={loading}
                      isMultiline={false}
                />
            }
            <CustomTextfield labelName={'Subject'} type={'subject'} required
                             value={subject} onValueChange={setSubject} disable={loading}
                             isMultiline={false}
            />
            <Editor labelName={"Body"} type={"null"} value={message} onValueChange={setMessage} />
            <div className={classes.buttonContainer}>
                <LoadingButton type={'submit'} loading={loading} text={'Send'}/>
            </div>
        </div>
    );
}