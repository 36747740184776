import React, { useContext, useState } from "react";
import Page from "../../general_components/Page";
import { Box, Container, IconButton, Stack, Typography } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { makeStyles } from "@mui/styles";
import JobsList from "./components/JobsList";
import { PRIMARY_COLOR_DarkNavyBlue } from "../../constants/Colors";
import { CareersContext } from "../../contexts/CareersContext";
import JobsModal from "./components/JobsModal";
import StackCustom from "../../general_components/StackCustom";

const useStyles = makeStyles({
  CircleAddButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: PRIMARY_COLOR_DarkNavyBlue,
    borderRadius: "50%",
    width: "35px",
    height: "35px",
  },
});

const Careers = () => {
  const classes = useStyles();
  const { allJobs, handleDeleteJob, handleAddJob } = useContext(CareersContext);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Page text="Blutech Consulting | Careers">
      <Container>
        <Stack gap={1}>
          {/* <Typography sx={{ fontSize: "25px", fontWeight: 600 }}>
            Careers
          </Typography> */}
          <StackCustom
            text={"Jobs"}
            children={
              <IconButton
                style={{
                  backgroundColor: PRIMARY_COLOR_DarkNavyBlue,
                  color: "#fff",
                  marginLeft: 6,
                }}
                variant={"contained"}
                onClick={handleOpen}
              >
                <AddRoundedIcon size={18} />
              </IconButton>
            }
          />
        </Stack>
        <Box mt={2}>
          {allJobs.map((item) => {
            return <JobsList jobs={item} handleDeleteJob={handleDeleteJob} />;
          })}
        </Box>
      </Container>
      <JobsModal
        title="Add Job"
        open={open}
        handleClose={handleClose}
        text="Add job"
        handlerFunction={handleAddJob}
      />
    </Page>
  );
};

export default Careers;
