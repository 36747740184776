import Main from "./main/Main";
import AuthenticationContextProvider from "./contexts/AuthContext";
import ThemeProvider from "./theme";
import ContactsContextProvider from "./contexts/ContactsContext";
import SubscribersContextProvider from "./contexts/SubscribersContext";
import CareerContextProvider from "./contexts/CareersContext";
import AdminsContextProvider from "./contexts/AdminsContext";
import NewsContextProvider from "./contexts/NewsContext";
import SocialIconContextProvider from "./contexts/SocialIconsContext";
import PeoplesContextProvider from "./contexts/PeoplesContext";

export default function App() {
  return (
    <ThemeProvider>
      <AuthenticationContextProvider>
        <ContactsContextProvider>
          <SubscribersContextProvider>
            <CareerContextProvider>
              <AdminsContextProvider>
                <NewsContextProvider>
                  <SocialIconContextProvider>
                    <PeoplesContextProvider>
                      <Main />
                    </PeoplesContextProvider>
                  </SocialIconContextProvider>
                </NewsContextProvider>
              </AdminsContextProvider>
            </CareerContextProvider>
          </SubscribersContextProvider>
        </ContactsContextProvider>
      </AuthenticationContextProvider>
    </ThemeProvider>
  );
}
