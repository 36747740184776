import {createContext, React, useEffect, useState} from "react";
import IndexServices from "../services/IndexServices";
import Loader from "../general_components/Loader";
import SuccessMessage from "../general_components/SuccessMessage";
import ErrorMessage from "../general_components/ErrorMessage";
import Cookies from "js-cookie";

export const CareersContext = createContext();

const filterList = [
  {id: "today", label: "Today",},
  {id: "yesterday", label: "Yesterday",},
  {id: "thisweek", label: "ThisWeek",},
  {id: "alltime", label: "AllTime",},
];

export default function CareerContextProvider({ children }) {

  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [clickedCandidates, setClickedCandidates] = useState(null);
  const [searchCandidates, setSearchCandidates] = useState("");
  const [filter, setFilter] = useState("Today");
  const [showDetails, setShowDetails] = useState(false);
  const [item, setItem] = useState(null);
  const [allJobs, setAllJobs] = useState([]);
  const [allCandidates, setAllCandidates] = useState([]);
  const storedRole = Cookies.get("role");
  const [jobName, setJobName] = useState("");
  const [overview, setOverview] = useState("");
  const [experience, setExperience] = useState("");
  const [jobStatus, setJobStatus] = useState(false);

  // for candidates
  const OnClickedCandidates = allCandidates.filter((item) => {
    return item.id === clickedCandidates;
  });

  const fetchCandidates = async () => {
    try {
      const response = await IndexServices.getCandidates();
      setAllCandidates(response?.data?.data);
    } catch (error) {
      // setError(true);
      // setErrorMessage("Something Went Wrong");
    }
  };

  const fetchJobs = async () => {
    try {
      const response = await IndexServices.getJobs();
      setAllJobs(response?.data?.data);
    } catch (error) {
      // setError(true);
      // setErrorMessage("Something Went Wrong");
    }
  };

  useEffect(() => {
    fetchJobs();
    fetchCandidates()
  }, []);

  const filterCandidates = (filterText) => {
    switch (filterText) {
      case "Today":
        const today = new Date();
        return allCandidates.filter((item) => {
          const itemDate = new Date(item.date);
          return (
            itemDate.getDate() === today.getDate() &&
            itemDate.getMonth() === today.getMonth() &&
            itemDate.getFullYear() === today.getFullYear()
          );
        });

      case "Yesterday":
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return allCandidates.filter((item) => {
          const itemDate = new Date(item.date);
          return (
            itemDate.getDate() === yesterday.getDate() &&
            itemDate.getMonth() === yesterday.getMonth() &&
            itemDate.getFullYear() === yesterday.getFullYear()
          );
        });

      case "ThisWeek":
        const newtoday = new Date();
        const startOfWeek = new Date(newtoday);
        startOfWeek.setDate(newtoday.getDate() - newtoday.getDay());

        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);

        return allCandidates.filter((item) => {
          const itemDate = new Date(item.date);
          return itemDate >= startOfWeek && itemDate <= endOfWeek;
        });

      case "AllTime":
        return allCandidates;

      case "search":
        return allCandidates.filter((item) => {
          return item.name
            .toLowerCase()
            .includes(searchCandidates.toLowerCase());
        });

      default:
        return allCandidates;
    }
  };

  const filteredcandidates = filterCandidates(filter);

  // for jobs

  const handleDeleteJob = (jobId) => {
    setOpen(true);
    IndexServices.deleteJob(jobId, { role: storedRole })
        .then((response) => {
          if(response.data.success) {
            console.log(allJobs)
            setAllJobs(allJobs.filter((item) => item.jobId !== jobId))
            setOpen(false);
            setConfirm(true);
            setTimeout(() => {
              setConfirm(false);
            }, 2000);
          } else {
            setError(true);
            setErrorMessage("Something Went Wrong");
          }
          setOpen(false);
        })
        .catch((e) => {
          setOpen(false);
          setError(true);
          setErrorMessage(e);
        });

  };
  const handleEditJob = (editId) => {
    const data = {
      jobName: jobName,
      overview: overview,
      experience: experience,
      isActive: jobStatus,
    }
    setOpen(true);
    IndexServices.updateJob(editId, data)
        .then((response) => {
          if (response.data.success) {
            console.log(response)
            fetchJobs();
            setOpen(false);
            setConfirm(true);
            setTimeout(() => {
              setConfirm(false);
            }, 2000);

          } else {
            setError(true);
            setErrorMessage("Something Went Wrong");
          }
          setOpen(false);
        })
        .catch((e) => {
          setOpen(false);
          setError(true);
          setErrorMessage("Something Went Wrong");
        });
    handleEmpty();
  };

  const handleAddJob = (data) => {
    setOpen(true);
    IndexServices.addJob(data).then(response => {
      if(response.data.success){
        setConfirm(true);
        setOpen(false);
        setTimeout(() => {
          setConfirm(false);
        }, 2000);
        setAllJobs((prevJobs) => [...prevJobs, data]);
      }else{
        setError(true);
        setErrorMessage("Something Went Wrong");
      }
    }).catch(error => {
      setError(true);
      setErrorMessage("Something Went Wrong");
    }).finally(() => {
      setOpen(false)
      handleEmpty()
    });
  };

  const handleEmpty = () => {
    setJobName('')
    setOverview('')
    setExperience('')
  }

  const handleFillData = (data) => {
    setJobName(data.jobName)
    setOverview(data.overview)
    setExperience(data.experience);
    setJobStatus(data.isActive);
  }

  return (
    <CareersContext.Provider
      value={{
        allJobs, setAllJobs,
        OnClickedCandidates,
        setClickedCandidates,
        handleDeleteJob,
        handleEditJob,
        allCandidates,
        clickedCandidates,
        showDetails,
        setShowDetails,
        item,
        setItem,
        filterList,
        filteredcandidates,
        setFilter,
        searchCandidates,
        setSearchCandidates,
        filter,
        handleAddJob, handleEmpty,
        jobName, setJobName, overview, setOverview, experience, setExperience,
        jobStatus, setJobStatus, handleFillData
      }}
    >
      {children}
      <Loader open={open} close={setOpen} />
      <SuccessMessage open={confirm} close={setConfirm} />
      <ErrorMessage open={error} close={setError} message={errorMessage} />
    </CareersContext.Provider>
  );
}
