import React, {useState} from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {styled} from "@mui/material/styles";
import AccountBar from "../../layouts/AccountBar";
import DashboardSidebar from "../../layouts/DashboardSidebar";
import { AuthenticationContext } from '../../contexts/AuthContext';
//import AccountBar from "../../layouts/AccountBar";
//import DashboardSidebar from "../../layouts/DashboardSidebar";

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100vh',
    overflow: 'hidden',
    backgroundColor: '#FFFFFF'
});

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: '7vh',
    [theme.breakpoints.up('lg')]: {
        // paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

function ProtectedRoute({children}) {
    const useAuth = () => {
        return React.useContext(AuthenticationContext);
    }

    const [open, setOpen] = useState(false);

    const RequireAuth = ({children}) => {
        let auth = useAuth();
        let location = useLocation();

        if (!auth.isAuthenticated) {
            return <Navigate to="/login" state={{ from: location }} replace />;
        }
        return children;
    }

    const MainComponent = ({children}) => {
        return (
            <RootStyle>
                <AccountBar onOpenSidebar={() => setOpen(true)} />
                <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
                <MainStyle>
                    {children}
                </MainStyle>
            </RootStyle>
        )
    }

    return (
        <RequireAuth>
            <MainComponent>
                {children}
            </MainComponent>
        </RequireAuth>
    );
}

export default ProtectedRoute;