import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import Loader from "../general_components/Loader";
import SuccessMessage from "../general_components/SuccessMessage";
import IndexServices from "../services/IndexServices";
import ErrorMessage from "../general_components/ErrorMessage";

export const AuthenticationContext = createContext();

export default function AuthenticationContextProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false),
    [token, setToken] = useState(
      Cookies.get("token") ? Cookies.get("token") : null
    ),
    [name, setName] = useState(
      Cookies.get("name") ? Cookies.get("name") : null
    ),
    [adminId, setAdminId] = useState(
      Cookies.get("adminId") ? Cookies.get("adminId") : null
    ),
    [email, setEmail] = useState(
      Cookies.get("email") ? Cookies.get("email") : null
    );
  const [userAccount, setUserAccount] = useState([]);

  useEffect(() => {
    const storedToken = Cookies.get("token");
    const storedName = Cookies.get("name");
    const storedAdminId = Cookies.get("adminId");
    const storedEmail = Cookies.get("email");

    if (storedToken && storedName && storedAdminId && storedEmail) {
      setToken(storedToken);
      setName(storedName);
      setAdminId(storedAdminId);
      setEmail(storedEmail);
      setIsAuthenticated(true);
    } else {
      logOut();
    }
  }, []);

  const signIn = async (email, password) => {
    setOpen(true);
    const loginData = { email: email, password: password };
    IndexServices.login(loginData)
      .then(async (response) => {
        if (response.data.success) {
          Cookies.set("token", response.data.data.token);
          Cookies.set("adminId", response.data.data.userId);
          Cookies.set("name", response.data.data.name);
          Cookies.set("email", response.data.data.email);
          Cookies.set("role", response.data.data.role);
          setOpen(false);
          setConfirm(true);
          setTimeout(() => {
            setConfirm(false);
          }, 2000);
          setToken(response.data.data.token);
          toggleState(response.data);
          toggleIsAuthenticated();
        } else {
          setOpen(false);
          setError(true);
          setErrorMessage("Something Went Wrong");
        }
      })
      .catch((e) => {
        setOpen(false);
        // setError(true);
        // setErrorMessage("Something Went Wrong");
      });
  };

  function toggleIsAuthenticated() {
    setIsAuthenticated(!isAuthenticated);
  }

  function toggleState(data) {
    setName(data.name);
    setAdminId(data.adminId);
    setEmail(data.email);
    setUserAccount(data);
  }

  async function logOut() {
    Cookies.remove();
    Cookies.remove("token");
    Cookies.remove("name");
    Cookies.remove("adminId");
    Cookies.remove("email");
    Cookies.remove("role");
    setName(null);
    setAdminId(null);
    setToken(null);
    setIsAuthenticated(false);
  }

  return (
    <AuthenticationContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        token,
        setToken,
        name,
        setName,
        adminId,
        setAdminId,
        toggleIsAuthenticated,
        toggleState,
        logOut,
        userAccount,
        setUserAccount,
        email,
        setEmail,
        signIn,
      }}
    >
      {children}
      <Loader open={open} close={setOpen} />
      <SuccessMessage open={confirm} close={setConfirm} />
      <ErrorMessage open={error} close={setError} message={errorMessage} />
    </AuthenticationContext.Provider>
  );
}
