import * as React from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Lottie from "react-lottie";
import { Typography } from "@mui/material";
import { AuthenticationContext } from "../../contexts/AuthContext";
import LoadingButton from "../../general_components/LoadingButton";
import Page from "../../general_components/Page";
import {
  PRIMARY_COLOR_DarkNavyBlue,
  PRIMARY_COLOR_MediumRoyalBlue,
  PRIMARY_COLOR_LightSkyBlue,
} from "../../constants/Colors";
import CustomTextField from "../../general_components/CustomTextField";

const useStyles = makeStyles({
  container: {
    backgroundColor: "#e5e5e569",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    textAlign: "center",
    paddingBottom: 25,
    color: PRIMARY_COLOR_DarkNavyBlue,
  },
  form: {
    width: 350,
  },
});

export default function Login() {
  const classes = useStyles();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const authenticationContext = React.useContext(AuthenticationContext),
    { signIn} = authenticationContext;
  
  const onSubmit = (e) => {
    e.preventDefault();
    if (password.length < 5) {
      alert("Password must be at least 5 characters.");
      return;
    }
    
    signIn(email, password);
  };

  return (
    <Page title={"Blutech Consulting  | Log In"}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6}>
          <div className={classes.container}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: require("../../assets/Lottie/authentication-lottie.json"),
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={250}
              width={250}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.container}
          style={{ backgroundColor: "#fff" }}
        >
          <form className={classes.form} onSubmit={onSubmit}>
            <Typography
              variant={"h5"}
              className={classes.title}
              style={{ fontWeight: 600 }}
            >
              Admin Login
            </Typography>
            <CustomTextField
              labelName={"Email"}
              type={"email"}
              required
              style={{ marginBottom: 15 }}
              value={email}
              onValueChange={setEmail}
              disable={loading}
              isMultiline={false}
            />
            <CustomTextField
              labelName={"Password"}
              type={"password"}
              required
              style={{ marginBottom: 25 }}
              value={password}
              onValueChange={setPassword}
              disable={loading}
              isMultiline={false}
            />
            <LoadingButton type={"submit"} loading={loading} text={"Login"} />
          </form>
        </Grid>
      </Grid>
    </Page>
  );
}
