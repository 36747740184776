import React, {useContext, useEffect, useState} from "react";
import Page from "../../general_components/Page";
import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CareersContext } from "../../contexts/CareersContext";
import CustomDialog from "../../general_components/CustomDialog";
import FilterListIcon from "@mui/icons-material/FilterList";
import CandidateList from "./components/CandidateList";
import CandidateDetail from "./components/CandidateDetail";
import CandidatesFilter from "./components/CandidatesFilter";
import {Navigate, useParams} from "react-router-dom";

const useStyles = makeStyles({
  filterBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "10px",
  },
  contactsBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "1.5rem",
  },
  filterCategory: {
    cursor: "pointer",
    fontWeight: "600",
    color: "gray",
  },
});

const Candidates = () => {

  const {jobId} = useParams();
  const classes = useStyles();
  const {
    setShowDetails, setItem, item, showDetails,
    allJobs, allCandidates
  } = useContext(CareersContext);

  const [filteredJob, setFilteredJob] = useState(null);
  const [filteredCandidate, setFilteredCandidate] = useState(null);

  useEffect(() => {
    if(jobId){
      const jobData = allJobs.filter(value => value?.jobId === jobId);
      const candidateData = allCandidates.filter(value => value?.jobId === jobId);
      if(jobData.length > 0){
        setFilteredJob(jobData[0]);
        setFilteredCandidate(candidateData);
      } else {
        // navigate('/careers')
      }
    }
  }, [jobId, allCandidates, allJobs]);


  return allCandidates ? (
    <Page title="Blutech Consulting | Candidates">
      <Container sx={{ mt: "1rem" }}>
        <Grid container gap={0.5}>
          <Grid xs={3.5}>
            <Stack>
              <Typography variant="h5">
                Candidates
              </Typography>
            </Stack>
            <Stack sx={{ display: "flex", alignItems: "start", gap: "20px" }}>
              <CandidateList
                candidates={filteredCandidate ? filteredCandidate : allCandidates}
                setShowDetails={setShowDetails}
                setItem={setItem}
                jobId={jobId} filteredJob={filteredJob}
              />
            </Stack>
          </Grid>
          <Grid xs={0.01} height={"80vh"}>
            <Divider orientation="vertical"></Divider>
          </Grid>
          <Grid xs={7}>
            {
              (showDetails && allCandidates.length !== 0) ? (
                  <Stack>
                    <Typography mb={2} variant="h5">
                      Details
                    </Typography>
                    <CandidateDetail filteredJob={filteredJob} item={item} />
                  </Stack>
              ) : null
            }
          </Grid>
        </Grid>
      </Container>
    </Page>
  ) : <Navigate to={'/careers'}/>
}

export default Candidates;
