import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { makeStyles } from "@mui/styles";
import ErrorIcon from "@mui/icons-material/Error";
import {
  BG_DRAWER_COLOR,
  PRIMARY_TEXT_COLOR,
  BG_SHADOW,
} from "../constants/Colors";

const useStyles = makeStyles({
  buttons: {
    display: "flex",
    flexGrow: 1,
    alignItems: "space-between",
    marginTop: 20,
  },
  confirmButton: {
    marginLeft: 10,
  },
});

export default function ErrorMessage({ open, close, message }) {
  const classes = useStyles();

  const handleClose = () => {
    close(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent style={{ minWidth: 300 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "5px",
          }}
        >
          {" "}
          <ErrorIcon color={"error"} fontSize="large" />
        </div>
        <DialogContentText
          id="alert-dialog-description"
          variant={"subtitle2"}
          textAlign={"center"}
        >
          {message}
        </DialogContentText>
        <div className={classes.buttons}>
          <Button
            onClick={handleClose}
            fullWidth={true}
            variant={"contained"}
            sx={{
              backgroundColor: BG_SHADOW,
              "&:hover, &:focus": {
                backgroundColor: BG_DRAWER_COLOR,
                color: PRIMARY_TEXT_COLOR,
              },
            }}
          >
            Ok
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
