
export default function Button(theme) {
    return {
        MuiButton: {
            styleOverrides: {
                contained: {
                    boxShadow: 'none',
                    borderRadius: 10,
                },
            }
        }
    };
}
