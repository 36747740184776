import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { PRIMARY_COLOR_DarkNavyBlue } from "../../../constants/Colors";
import CustomTextField from "../../../general_components/CustomTextField";
import LoadingButton from "../../../general_components/LoadingButton";
import Editor from "../../subscribers/components/EmailEditor";
import AddProfileImage from "./AddProfileImage";
import { convertToRaw } from "draft-js";
import { PeoplesContext } from "../../../contexts/PeoplesContext";

export default function LeadershipTeamModel(props) {
  const {  open, handleClose, handlerFunction, id, previousTeam,type,selectedIdFunction } =
    props;

  const {
    name,
    setName,
    teamProfileImage,
    setTeamProfileImage,
    designation,
    setDesignation,
    description,
    setDescription,
    teamProfileRawImage,
    setTeamProfileRawImage,
    isNewsFile,
    setIsNewsFile,
    editMode,
    setEditMode,
  } = useContext(PeoplesContext);

  const handelSubmit = () => {
    const descriptionHtml = JSON.stringify(
      convertToRaw(description.getCurrentContent())
    );
    const data = {
      name: name,
      description: descriptionHtml,
      designation: designation,
      image: teamProfileRawImage?.file,
    };

    handlerFunction(data, id, previousTeam);
    setDescription("");
    handleClose();
    setTeamProfileImage(null);
    setTeamProfileRawImage(null);
    if (editMode) {
      selectedIdFunction("");
      setEditMode(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>
        <Typography
          variant={"h6"}
          style={{ color: PRIMARY_COLOR_DarkNavyBlue, fontWeight: "600" }}
        >
          {type === "add" ? "Add Leadership Team" : "Edit Leadership Team"}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ width: 400, paddingTop: 10 }}>
        <form>
          <Box display={"flex"} justifyContent={"space-around"}>
            <AddProfileImage
              teamProfileImage={teamProfileImage}
              teamProfileRawImage={teamProfileRawImage}
              isNewsFile={isNewsFile}
              setTeamProfileImage={setTeamProfileImage}
              setIsNewsFile={setIsNewsFile}
              setTeamProfileRawImage={setTeamProfileRawImage}
              editMode={editMode}
              setEditMode={setEditMode}
            />
          </Box>
          <CustomTextField
            value={name}
            onValueChange={setName}
            labelName="Name"
            required
            style={{ marginBottom: 15 }}
          />
          <CustomTextField
            value={designation}
            onValueChange={setDesignation}
            labelName="Designation"
            type="text"
            required
            style={{ marginBottom: 15 }}
          />
          <Editor
            labelName={"Description"}
            type={"null"}
            value={description}
            onValueChange={setDescription}
            isTeam={true}
          />
          <div onClick={handelSubmit} style={{ marginTop: 15 }}>
            <LoadingButton text={type === "add" ? "Add" : "Update"} />
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
