import React, { useContext, useState } from "react";
import Page from "../../general_components/Page";
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import ContactsCard from "./components/ContactsCard";
import { ContactsContext } from "../../contexts/ContactsContext";
import FilterListIcon from "@mui/icons-material/FilterList";
import ContactDetails from "./components/ContactDetails";
import CustomDialog from "../../general_components/CustomDialog";
import CustomFilter from "../../general_components/CustomFilter";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  contactsBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "1.5rem",
  },
  filterBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "2px",
  },
  filterCategory: {
    cursor: "pointer",
    fontWeight: "600",
    color: "gray",
  },
  contactCard: {
    display: "flex",
    alignItems: "start",
    gap: "8px",
  },
});

const Contact = () => {
  const classes = useStyles();
  const {
    setClickedUser, OnClickedUser, filteredUser,
    setFilter, clickedUser, varifyId, filter
  } = useContext(ContactsContext);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Page title="Blutech Consulting | Contact">
      <Container>
        <Grid container gap={0.8}>
          <Grid xs={4.2}>
            <Box className={classes.contactsBox}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant="h5">Contacts</Typography>
                <Box className={classes.filterBox}>
                  <Typography sx={{ fontWeight: "600", color: "gray" }}>
                    Filter
                  </Typography>
                  <IconButton>
                    <FilterListIcon color="gray" onClick={handleOpen} />
                  </IconButton>
                </Box>
              </Stack>
              <Stack direction={"row"} sx={{mb:"-15px"}}>
                <Typography
                  className={classes.filterCategory}
                  onClick={() => {
                    setFilter("Yesterday");
                  }}
                  variant="body2"
                >
                  {filter}
                </Typography>
              </Stack>
            </Box>
            <Stack
              sx={{ maxHeight: "78vh", overflowY: "auto" }}
              className={classes.contactCard}
            >
              {filteredUser.length !== 0 ? (
                filteredUser.map((item) => {
                  return (
                    <div
                      onClick={() => {
                        setClickedUser(item.contactId);
                      }}
                      key={item.contactId}
                      style={{ cursor: "pointer" }}
                    >
                      <ContactsCard varifyId={varifyId} item={item} />
                    </div>
                  );
                })
              ) : (
                <Typography>No Contact Found,</Typography>
              )}
            </Stack>
          </Grid>
          <Grid xs={0.001} height={"90vh"} sx={{ ml: "-5px" }}>
            <Divider orientation="vertical"></Divider>
          </Grid>
          <Grid xs={6.5}>
            {filteredUser.length !== 0 && clickedUser && (
              <Stack>
                <Typography mb={2} variant="h5">
                  Details
                </Typography>
                <ContactDetails filteredUser={OnClickedUser} />
              </Stack>
            )}
          </Grid>
        </Grid>
      </Container>
      <CustomDialog
        open={open}
        handleClose={handleClose}
        children={<CustomFilter handleClose={handleClose} />}
      />
    </Page>
  );
};

export default Contact;
