
import {Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useEffect, useState} from "react";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {PRIMARY_COLOR_DarkNavyBlue} from "../../../constants/Colors";

const useStyles = makeStyles({
    root: {
        width: '100%',
        // padding: 2,
    },
    title: {
        textOverflow: 'ellipsis',
    },
    editButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    }
})


export default function SubscribersList({setOpen, setEmail, data, setID})
{
    const classes = useStyles();

    const [hover, setHover] = useState(-1);
    const [highlight, setHighlight] = useState(-1);

    
    return (
        <div className={classes.root}>
            <List
                sx={{
                    maxWidth: '30vw', minWidth: '30vw', maxHeight: '85vh', overflowY: 'auto',
                    paddingRight: 0.5, paddingLeft: 0.5
                }}
            >
                {
                    data.map((item, index) => {
                        return(
                            <ListItem
                                button
                                className={classes.editButton}
                                sx={{
                                    padding: 0, borderRadius: 5,
                                    paddingTop: '1vh', paddingBottom: '1vh',
                                    paddingLeft: '1vh', paddingRight: '1vh',
                                    backgroundColor: highlight === item.id ? 'action.selected' : null,
                                    // bgcolor: 'action.selected'
                                }}
                                onMouseOver={() => setHover(index)}
                                onMouseLeave={() => setHover(-1)}
                                onClick={() => {
                                    setHighlight(item.id)
                                    setEmail(item.email)
                                }}
                                secondaryAction={
                                    (hover === index) ?
                                        <IconButton edge="end" aria-label="delete" onClick={() => {
                                            setID(item._id)
                                            setOpen(true)}}
                                                    sx={{zIndex: 100}}
                                        >
                                            <DeleteRoundedIcon />
                                        </IconButton>
                                        : null
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar style={{backgroundColor: highlight === item.id ? PRIMARY_COLOR_DarkNavyBlue : null}}>
                                        {index + 1}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    // className={classes.title}
                                    sx={{color: highlight === item.id ? PRIMARY_COLOR_DarkNavyBlue : null,
                                        textOverflow: 'ellipsis'}}
                                    primary={item.email}
                                />
                            </ListItem>
                        )
                    })
                }
            </List>
        </div>
    );
}