import { Grid, Typography, CircularProgress } from "@mui/material";
import PeoplesCard from "./PeoplesCard";


export default function PeoplesList({ team, isLoading }) {
  
  return (
    <Grid container spacing={8} mb={2}>
      {isLoading ? (
        <Grid item xs={12}>
          <CircularProgress color="inherit" />
        </Grid>
      ) : team.length > 0 ? (
        team.map((item, index) => (
          <Grid key={team._id} item xs={12} sm={6} md={3}>
            <PeoplesCard
              item={item}
              index={index}
            />
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <Typography variant={"h6"}>No Peoples Found</Typography>
        </Grid>
      )}
    </Grid>
  );
}
