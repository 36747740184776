import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { BLACK } from "../../../constants/Colors";
import CustomTextField from "../../../general_components/CustomTextField";
import LoadingButton from "../../../general_components/LoadingButton";
import {CareersContext} from "../../../contexts/CareersContext";

const JobsModal = ({ title, open, handleClose, text, id, handlerFunction }) => {

  const { jobName, setJobName, overview, setOverview,
    experience, setExperience } = useContext(CareersContext);


  const handelSubmit = () => {
    const data = {
      jobName: jobName,
      overview: overview,
      experience: experience,
      isActive: true,
    }

    handlerFunction(data, id);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>
        <Typography
          variant={"body1"}
          style={{ color: BLACK, fontWeight: "600", marginBottom: "-20px" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ width: 450, paddingTop: 15 }}>
        <form>
          <CustomTextField
            value={jobName}
            onValueChange={setJobName}
            labelName="Role*"
          />
          <CustomTextField
            value={overview}
            onValueChange={setOverview}
            labelName="Overview*"
            isMultiline={true} rows={4}
          />
          <CustomTextField
            value={experience}
            onValueChange={setExperience}
            labelName="Experience*"
            isMultiline={true}
          />
          <div onClick={handelSubmit} style={{ marginTop: 15 }}>
            <LoadingButton text={text} />
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default JobsModal;
