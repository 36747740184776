import axios from "axios";
import { getAPIEndpointUrl, getToken} from "./ServerRequestModifier";

export default axios.create({
  baseURL: getAPIEndpointUrl() + "/api/admin",
  headers: {
    "Content-type": "multipart/form-data",
    "token": getToken(),
  },
});
