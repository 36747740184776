import React from 'react';
import Page from "../../general_components/Page";
import { Container } from '@mui/material';
import SocialLinks from "../sociallinks/SocialLinks";

export default function Home() {

    return (
      <Page title="Blutech Consulting | Home" style={{ paddingBottom: "6vh" }}>
        <Container>
          <SocialLinks />
        </Container>
      </Page>
    );
}
