import React, { useEffect, useState } from "react";

export const NewsContext = React.createContext();

export default function NewsContextProvider({ children }) {
  const [allNews, setAllNews] = useState([]);
  const [newsTitle, setNewsTitle] = useState("");
  const [newsTags, setNewsTags] = useState("");
  const [allnewsTags, setAllNewsTags] = useState([]);
  const [newsDate, setNewsDate] = useState("");
  const [newsId, setNewsId] = useState(null);
  
  // for news images
  const [desktopImage, setDesktopImage] = useState(""),
    [desktopRawImage, setDesktopRawImage] = useState([]),
    [desktopMultipleImages, setDesktopMultipleImages] = useState(""),
    [desktopMultipleRawImages, setDesktopMultipleRawImages] = useState(null),
    [isNewsFile, setIsNewsFile] = useState(false);

  const [mobileImage, setMobileImage] = useState(""),
    [mobileRawImage, setMobileRawImage] = useState([]),
    [mobileMultipleImages, setmobileMultipleImages] = useState(""),
    [mobileMultipleRawImages, setMobileMultipleRawImages] = useState(null);
    
  const handleDeleteNews = (NewsId) => {
    const updatedNews = allNews.filter((news) => news.newsId !== NewsId);
    setAllNews(updatedNews);
  };
  
  const handleEditNews = (newNewsData, NewsId) => {
    const updatedNews = allNews.map((news) => {
      if (news.newsId === NewsId) {
        const updatedNewsData = {
          newsTitle:
            newNewsData.newsTitle === ""
              ? news.newsTitle
              : newNewsData.newsTitle,
          newsTags: [...(newNewsData.newsTags || [])],
          newsdescription:
            newNewsData.newsdescription === null
              ? news.newsdescription
              : newNewsData.newsdescription,
          newsDate:
            newNewsData.newsDate === "" ? news.newsDate : newNewsData.newsDate,
          desktopFile:
            newNewsData.desktopFile === null
              ? news.desktopFile
              : newNewsData.desktopFile,
          mobileFile:
            newNewsData.mobileFile === null
              ? news.mobileFile
              : newNewsData.mobileFile,
        };
        return { ...news, ...updatedNewsData };
      }
      return news;
    });
    setAllNews(updatedNews);
    setNewsTitle("");
    setNewsTags("");
    setNewsDate("");
  };
  
  const handleAddNews = (newNewsData) => {
    const newNews = {
      newsId: allNews.length - 1 + 1,
      ...newNewsData,
    };
    setAllNews((prevJobs) => [...prevJobs, newNews]);
    setNewsTitle("");
    setNewsTags("");
    setNewsDate("");
  };
  
  const handleRemoveTag = (tagIndex) => {
    setAllNewsTags((prevTags) => {
      return prevTags.filter((_, index) => index !== tagIndex);
    });
  };
  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter" && newsTags.trim() !== "") {
      e.preventDefault();
      setAllNewsTags([...allnewsTags, newsTags.trim()]);
      setNewsTags("");
    }
  };
  const handleTagInputChange = (e) => {
    setNewsTags(e.target.value);
  };
  return (
    <NewsContext.Provider
      value={{
        allNews,
        handleDeleteNews,
        handleEditNews,
        handleAddNews,
        newsTitle,
        setNewsTitle,
        newsTags,
        setNewsTags,
        newsDate,
        setNewsDate,
        desktopImage,
        setDesktopImage,
        desktopRawImage,
        setDesktopRawImage,
        desktopMultipleImages,
        setDesktopMultipleImages,
        desktopMultipleRawImages,
        setDesktopMultipleRawImages,
        isNewsFile,
        setIsNewsFile,
        mobileImage,
        setMobileImage,
        mobileRawImage,
        setMobileRawImage,
        mobileMultipleImages,
        setmobileMultipleImages,
        mobileMultipleRawImages,
        setMobileMultipleRawImages,
        allnewsTags,
        setAllNewsTags,
        handleRemoveTag,
        handleEnterKeyPress,
        handleTagInputChange,
        newsId,
        setNewsId,
      }}
    >
      {children}
    </NewsContext.Provider>
  );
}
