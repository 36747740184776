import Cookies from "js-cookie";

const TOKEN = Cookies.get("token");

export const getAPIEndpointUrl = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return 'http://localhost:3001';
  }
  return "https://alphaapibtc.jaffer.com";
};

export const getToken = () => {
  return TOKEN;
};

export const addTokenToData = (data) => {
  return { token: getToken(), ...data };
};

export const addTokenToURL = (url) => {
  return `${url}?token=${getToken()}`;
};

export const saveToken = (Token) => {
  return Token;
};
