import React from 'react'
import CustomDialog from "../../../general_components/CustomDialog";
import LoadingButton from "../../../general_components/LoadingButton";
import {InputAdornment, Typography} from '@mui/material';
import { PRIMARY_COLOR_DarkNavyBlue } from '../../../constants/Colors';
import { SocialIconContext } from '../../../contexts/SocialIconsContext';
import { getSocialMediaIconandColor} from "../../../utils/Functions";
import CustomTextField from "../../../general_components/CustomTextField";

const SocialLinkModal = ({handlerFunction, setOpen, open, handleClose, text, title, id,}) => {

  const { Link, setLink, socialPlatform, setSocialPlatform, SocialLinkArray, editMode} = React.useContext(SocialIconContext);

  const result = getSocialMediaIconandColor(socialPlatform ? socialPlatform : '');

  const handelSubmit = (e) => {
      e.preventDefault();
      const data={ link: result?.link + Link, plateform: socialPlatform };
      handlerFunction(data, id);
      setOpen(false);
  };

  return (
      <CustomDialog
          open={open}
          handleClose={handleClose}
          children={
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              {" "}
              <Typography
                  variant={"h6"}
                  style={{ color: PRIMARY_COLOR_DarkNavyBlue, fontWeight: "600" }}
              >
                {title}
              </Typography>
              <form onSubmit={handelSubmit}>
                <CustomTextField
                    labelName={'Social Links'} required onValueChange={setSocialPlatform}
                    value={socialPlatform} fullWidth={true} select={true} data={SocialLinkArray}
                />
                <CustomTextField
                  labelName={'Links'}  type={'text'} required 
                  style={{ marginBottom: 25 }} value={Link} onValueChange={setLink} 
                  isMultiline={false}
                  InputProps={
                    editMode === false
                      ? {
                          startAdornment: (
                            <InputAdornment position="start">
                              {result?.path}
                            </InputAdornment>
                          ),
                        }
                      : {
                            startAdornment: (
                                <InputAdornment position="start">
                                    {result?.path}
                                </InputAdornment>
                            ),
                        }
                  }
                />

                <div style={{ marginTop: 15 }}>
                  <LoadingButton text={text} type={'submit'} />
                </div>
              </form>
            </div>
          }
      />
  );
};

export default SocialLinkModal