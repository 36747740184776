import { useRef, useState } from 'react';
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, Typography, Avatar, IconButton } from '@mui/material';
import * as React from "react";
import {AuthenticationContext} from "../contexts/AuthContext";
import MenuPopover from "../general_components/MenuPopover";
import {PRIMARY_COLOR_DarkNavyBlue} from "../constants/Colors";
import Cookies from 'js-cookie';



export default function AccountPopover() {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const storedName = Cookies.get("name");
    const storedEmail = Cookies.get("email");
    const storedRole = Cookies.get("role");
    const authenticationContext = React.useContext(AuthenticationContext),
        {logOut} = authenticationContext

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    return (
      <>
        <IconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            ...(open && {
              "&:before": {
                zIndex: 1,
                content: "''",
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                position: "absolute",
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
              },
            }),
          }}
        >
          <Avatar
            src={authenticationContext.userAccount.avatar}
            style={{ backgroundColor: PRIMARY_COLOR_DarkNavyBlue }}
            alt="photoURL"
          />
        </IconButton>

        <MenuPopover
          open={open}
          onClose={handleClose}
          anchorEl={anchorRef.current}
          sx={{ width: 220, backgroundColor: "#FFF", borderRadius: 5 }}
        >
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography
              variant="subtitle1"
              sx={{ color: PRIMARY_COLOR_DarkNavyBlue, fontWeight: "bold" }}
              noWrap
            >
              {storedName}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
              {storedEmail}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
              {storedRole === "Super_Admin" ? "Super Admin" : "Admin"}
            </Typography>
          </Box>

          <Divider sx={{ my: 1 }} />

          <Box sx={{ p: 2, pt: 1.5 }}>
            <Button
              fullWidth
              color="inherit"
              variant="outlined"
              onClick={() => {
                logOut();
              }}
            >
              Logout
            </Button>
          </Box>
        </MenuPopover>
      </>
    );
}
