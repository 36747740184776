import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Avatar} from '@mui/material';
import * as React from "react";
import sidebarConfig from "./SidebarConfig";
import {AuthenticationContext} from "../contexts/AuthContext";
import useResponsive from "../hooks/useResponsive";
import Scrollbar from "../general_components/Scrollbar";
import Logo from "../general_components/Logo";
import NavSection from "./NavSection";
import {GRAY_LIGHT, PRIMARY_COLOR_DarkNavyBlue,  SECONDARY_COLOR_DarkMaroon, WHITE_T} from "../constants/Colors";
import Cookies from 'js-cookie';


const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH
    }
}));

const AccountStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
}));


export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
    const { pathname } = useLocation();
    const authenticationContext = React.useContext(AuthenticationContext);

    const isDesktop = useResponsive('up', 'lg');
      const storedName = Cookies.get("name");
      const storedRole = Cookies.get("role");
    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
    }, [pathname]);

    const renderContent = (
      <Scrollbar
        sx={{
          height: "100%",
          "& .simplebar-content": {
            height: 1,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Box
          sx={{
            px: 4,
            py: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
            marginTop: 2.5,
            marginBottom: 2,
          }}
        >
          <Logo sx={{ marginRight: 2 }} />
        </Box>
        <NavSection navConfig={sidebarConfig} />
        <Box
          sx={{
            mb: 2,
            mx: 2.5,
            minWidth: "85%",
            maxWidth: "85%",
            backgroundColor: WHITE_T,
            borderRadius: 5,
            userSelect: "none",
            bottom: 0,
            position: "absolute",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
          }}
        >
          <AccountStyle>
            <Avatar
              src={authenticationContext.userAccount.avatar}
              style={{ backgroundColor: PRIMARY_COLOR_DarkNavyBlue }}
              alt="photoURL"
            />
            <Box
              sx={{
                ml: 2,
                width: 150,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Typography
                variant="p"
                noWrap
                sx={{
                  color: PRIMARY_COLOR_DarkNavyBlue,
                  fontSize: 18,
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {storedName}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {storedRole === "Super_Admin" ? "Super Admin" : "Admin"}
              </Typography>
            </Box>
          </AccountStyle>
        </Box>
      </Scrollbar>
    );

    return (
      <RootStyle>
        {!isDesktop && (
          <Drawer
            open={isOpenSidebar}
            onClose={onCloseSidebar}
            PaperProps={{
              sx: { width: DRAWER_WIDTH },
            }}
          >
            {renderContent}
          </Drawer>
        )}

        {isDesktop && (
          <Drawer
            open
            variant="persistent"
            PaperProps={{
              sx: {
                width: DRAWER_WIDTH,
                // backgroundColor: '#B5DEEF',
              },
            }}
          >
            {renderContent}
          </Drawer>
        )}
      </RootStyle>
    );
}
