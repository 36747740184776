import {
  Box,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { makeStyles } from "@mui/styles";
import { alpha, useTheme } from "@mui/material/styles";
import {
  PRIMARY_COLOR_DarkNavyBlue,
  PRIMARY_COLOR_MediumRoyalBlue,
} from "../../../constants/Colors";
import { Link } from "react-router-dom";
import JobsModal from "./JobsModal";
import { CareersContext } from "../../../contexts/CareersContext";
import {formatDate, getShortDate} from "../../../utils/Functions";
import ConfirmationDialog from "../../../general_components/ConformationDialog";

const useStyles = makeStyles({
  detailsHeadings: {
    fontSize: "18px",
    fontWeight: 400,
    color: "gray",
  },
  details: {
    fontSize: "16px",
    fontWeight: 550,
  },
  detailsGrid1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  detailsGrid2: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "4rem",
    alignItems: "center",
  },
});

const JobsList = ({ jobs }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [editId, setEditId] = useState(-1);
  const [selectedId, setSelectedId] = useState(-1);
  const [expandedId, setExpandedId] = useState(-1);
  const { handleDeleteJob, handleEditJob, handleFillData, handleEmpty, allCandidates } = useContext(CareersContext);

  const handleExpandClick = (id) => {
    setExpandedId(expandedId === id ? -1 : id);
  };
  const handleOpen = (data) => {
    handleFillData(data);
    setOpen(true);
  };
  const handleClose = () => {
    handleEmpty();
    setOpen(false);
  };

  const [filteredCandidate, setFilteredCandidate] = useState(null);

  useEffect(() => {
    if(jobs){
      const candidateData = allCandidates.filter(value => value?.jobId === jobs.jobId);
      if(candidateData.length > 0){
        setFilteredCandidate(candidateData);
        console.log(candidateData)
      } else {
        // navigate('/careers')
      }
    }
  }, [jobs, allCandidates]);

  return (
    <>
      <Stack
        sx={{
          width: "97.25%",
          marginTop: "1rem",
          marginBottom: "1rem",
          padding: "3vh 1.5vw",
          borderRadius: 5,
          backgroundColor: alpha(
            PRIMARY_COLOR_MediumRoyalBlue,
            theme.palette.action.selectedOpacity
          ),
        }}
      >
        <Grid container>
          <Grid xs={3.2} className={classes.detailsGrid1}>
            <Typography className={classes.detailsHeadings}>Role</Typography>
            <Typography
              sx={{
                color: PRIMARY_COLOR_DarkNavyBlue,
                cursor: "pointer",
                fontWeight: 700,
                fontSize: "20px",
              }}
              expand={expandedId}
              onClick={() => handleExpandClick(jobs.jobId)}
            >
              {jobs.jobName}
            </Typography>
          </Grid>
          <Grid xs={8.8} className={classes.detailsGrid2}>
            {/*<Stack gap={1}>*/}
            {/*  <Typography className={classes.detailsHeadings}>*/}
            {/*    Deadline*/}
            {/*  </Typography>*/}
            {/*  <Typography variant="h5" className={classes.details}>*/}
            {/*    {jobs.jobDeadline}*/}
            {/*  </Typography>*/}
            {/*</Stack>*/}
            {/*<Stack gap={1}>*/}
            {/*  <Typography className={classes.detailsHeadings}>*/}
            {/*    Location*/}
            {/*  </Typography>*/}
            {/*  <Typography variant="h5" className={classes.details}>*/}
            {/*    {jobs.jobLocation}*/}
            {/*  </Typography>*/}
            {/*</Stack>*/}
            {
              allCandidates ?
                  <Stack gap={1}>
                    <Link
                        style={{ textDecoration: "none" }}
                        to={filteredCandidate ? `/careers/candidates/${jobs.jobId}` : '/careers'}
                    >
                      <Typography className={classes.detailsHeadings}>
                        Candidates
                      </Typography>
                    </Link>
                    <Typography variant="h5" className={classes.details}>
                      {filteredCandidate ? filteredCandidate?.length : 0}
                    </Typography>
                  </Stack>
                  : null
            }
            <Stack sx={{ mt: "-8px" }}>
              <Box
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gap={1}
              >
                {/*<Typography className={classes.detailsHeadings}>*/}
                {/*  Active*/}
                {/*</Typography>*/}
                {/*<Checkbox*/}
                {/*  size="medium"*/}
                {/*  checked={jobs.isActive}*/}
                {/*  sx={{ color: "gray" }}*/}
                {/*/>*/}
                <IconButton onClick={() => {
                    setEditId(jobs.jobId)
                    handleOpen(jobs)
                  }}
                >
                  <CreateRoundedIcon sx={{ color: "gray" }} size="medium" />
                </IconButton>
                <IconButton
                    onClick={() => {
                      setOpenDel(true)
                      setSelectedId(jobs.jobId)
                    }}
                >
                  <DeleteRoundedIcon sx={{ color: "gray" }} size="medium" />
                </IconButton>
              </Box>
              {/*<Typography variant="body1" style={{textAlign: 'right'}}>*/}
              {/*  {getShortDate(jobs.createdAt)}*/}
              {/*</Typography>*/}
            </Stack>
          </Grid>
        </Grid>
        <Collapse in={expandedId === jobs.jobId} timeout={"auto"}>
          <Box sx={{ maxHeight: "40vh", overflow: "auto", paddingTop: 1 }}>
            <Box>
              <Typography className={classes.detailsHeadings}>
                Overview
              </Typography>
              <Typography variant={'h6'}>
                {jobs.overview}
              </Typography>
            </Box>
            <Stack pt={2}>
              <Typography className={classes.detailsHeadings}>
                Experience
              </Typography>
              <Typography variant={'h6'}>{jobs.experience}</Typography>
            </Stack>
          </Box>
        </Collapse>
      </Stack>
      <JobsModal
        title="Edit Job"
        open={open}
        handleClose={handleClose}
        text="Update Job"
        id={jobs.jobId}
        handlerFunction={() => handleEditJob(editId)}
      />
      <ConfirmationDialog
          loading={false}
          open={openDel}
          close={setOpenDel}
          confirm={() => handleDeleteJob(selectedId)}
          title="Confirmation"
          message="Are you sure you want to delete this?"
          cancelBtnText="Cancel"
          confirmBtnText="Delete"
          pageName="admin"
      />
    </>
  );
};

export default JobsList;
