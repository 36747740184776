import React, {useEffect, useState} from "react";
import { createTheme } from '@mui/material/styles';
import {Typography} from "@mui/material";
import {makeStyles, ThemeProvider} from "@mui/styles";
import { Editor as Editor2 } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


import {PRIMARY_COLOR_DarkNavyBlue, PRIMARY_COLOR_LightSkyBlue} from "../../../constants/Colors";

const themeOptions = createTheme({
    overrides: {
        MUIRichTextEditor: {
            root: {
                width: "100%",
                height: 300,
            },
            editor: {
                height: 250,
                padding: '0px 10px',
                maxHeight: 250,
                overflow: 'hidden',
                overflowY: 'auto'
            }
        }
    }
});

const useStyles = makeStyles({
    editor: {
        borderRadius: 10,
        backgroundColor: '#fff',
        minHeight: 300,
        padding: '2px 10px',
        marginTop: 2,
        border: '1px solid #bdbdbd',
        "&:hover, &:focus": {
            border: '2px solid ' + PRIMARY_COLOR_DarkNavyBlue,
            padding: '1px 9px',
        }
    },
});

export default function Editor({labelName, value, onValueChange, type}){
    const classes = useStyles();
    useEffect(() => {
        // let raw = convertFromRaw(text);
        // const editorValue = EditorState.createWithContent(raw.getCurrentContent());
        // setText(raw);
    }, []);

    const handleChange = state => {
        onValueChange(state);
    };

    return (
        <>
            <Typography variant={'caption'} style={{lineHeight: 0}} color={'textSecondary'}>{labelName}</Typography>
            <div className={classes.editor}>
                <ThemeProvider theme={themeOptions}>
                    {/*<MUIRichTextEditor*/}
                    {/*    style={{backgroundColor: 'red'}}*/}
                    {/*    defaultValue={conve}*/}
                    {/*    onSave={save}*/}
                    {/*    inlineToolbar={true}*/}
                    {/*    onChange={handleChange('content')}*/}
                    {/*/>*/}
                    <Editor2
                        editorState={value}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={handleChange}
                        toolbar={
                            {
                                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link'],
                                inline: {
                                    inDropdown: type === 'MOBILE',
                                    className: undefined,
                                    component: undefined,
                                    dropdownClassName: undefined,
                                    options: ['bold', 'italic', 'underline'],
                                },
                                blockType: {
                                    inDropdown: true,
                                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                                    className: undefined,
                                    component: undefined,
                                    dropdownClassName: undefined,
                                },
                                fontSize: {
                                    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                                    className: undefined,
                                    component: undefined,
                                    dropdownClassName: undefined,
                                },
                                list: {
                                    inDropdown: type === 'MOBILE',
                                    className: undefined,
                                    component: undefined,
                                    dropdownClassName: undefined,
                                    options: ['unordered', 'ordered'],
                                },
                                textAlign: {
                                    inDropdown: type === 'MOBILE',
                                    className: undefined,
                                    component: undefined,
                                    dropdownClassName: undefined,
                                    options: ['left', 'center', 'right', 'justify'],
                                },
                                colorPicker: {
                                    className: undefined,
                                    component: undefined,
                                    popupClassName: undefined,
                                    colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                                        'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                                        'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                                        'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                                        'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                                        'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                                },
                                link: {
                                    inDropdown: type === 'MOBILE',
                                    className: undefined,
                                    component: undefined,
                                    popupClassName: undefined,
                                    dropdownClassName: undefined,
                                    showOpenOptionOnHover: true,
                                    defaultTargetOption: '_self',
                                    options: ['link', 'unlink'],
                                    linkCallback: undefined
                                },
                            }
                        }
                    />
                </ThemeProvider>
            </div>
            {/*<pre variant={'caption'} style={{lineHeight: 0}} color={'textSecondary'}>*/}
            {/*    {text}*/}
            {/*</pre>*/}
        </>
    );
}