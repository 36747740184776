import React, { useContext, useState } from "react";
import { Typography, Box, ButtonGroup, Button, Stack } from "@mui/material";
import { ContactsContext } from "../contexts/ContactsContext";


export default function CustomFilter({handleClose}) {
  const { filter, setFilter,filterList } = useContext(ContactsContext);
  const [itemLabel, setItemLabel] = useState(filter);
  const handleFilterChange=(filterText)=> {
    setItemLabel(filterText);  
  }
  const submitFilter = (e) => {
    e.preventDefault();
    setFilter(itemLabel)
    setItemLabel(null)
    handleClose()
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={3}>
      <Typography mt={"-20px"} variant={"h5"}>
        Filter Contacts
      </Typography>
      <Stack gap={0}>
        <Typography sx={{fontWeight:"600", paddingBottom: 1}}>Date</Typography>
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          {filterList.map((item) => {
            return (
              <Button id={item.id}
              onClick={() => handleFilterChange(item.label)}
              variant={itemLabel === item.label ? "contained" : "outlined"}
              >
                {item.label}
              </Button>
            );
          })}
        </ButtonGroup>
        <Button onClick={submitFilter} sx={{ mt: "30px" }} variant="contained">
          Apply Filter
        </Button>
      </Stack>
    </Box>
  );
}
