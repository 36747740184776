import React, {useState} from 'react';
import {EditorState} from "draft-js";
import {faker} from "@faker-js/faker";

export const SubscribersContext = React.createContext();

const Dummy_subscribers = [
  {
    id: 1,
    email: "muhammad@gmail.com",
  },
  {
    id: 2,
    email: "zubair@gmail.com",
  },
  {
    id: 3,
    email: "iqbal@gmail.com",
  },
];

export default function SubscribersContextProvider({children}) {

    // Subscribers data
    const [data, setData] = useState([...Array(20)].map((_, index) => ({
        id: faker.string.uuid(),
        email: faker.internet.email(),
    })) );

    // useEffect(() => {
    //     setOpen(true)
    //     IndexService.getSubscribers().then(res => {
    //         console.log(res)
    //         if(res.data.success){
    //             console.log(res.data.data)
    //             setData(res.data.data);
    //             setOpen(false)
    //             setConfirm(true);
    //             setTimeout(() => {
    //                 setConfirm(false);
    //             })
    //         }else {
    //             setOpen(false)
    //             alert(res.data.error)
    //         }
    //     }).catch(e => {
    //         setOpen(false)
    //         alert(e)
    //     })
    // }, [])

    // Values
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(EditorState.createEmpty()),
        [email, setEmail] = useState(''),
        [subject, setSubject] = useState('');

    const [openDel, setOpenDel] = useState(false),
        [load, setLoad] = useState(false),
        [id, setID] = useState(-1);

    const [show, setShow] = useState(false);
    const handleDelete = () => {
        setData(data.filter((item) => item.id !== id))
        setOpenDel(false);
        setID(-1)
    }

    const handleCopy = () => {
        let subscribers = '';
        data.forEach((value, index) => {
            subscribers =  subscribers + value.email;
            if(index < data.length - 1){
                subscribers = subscribers + ", ";
            }
        })
        navigator.clipboard.writeText(subscribers).then(r => setShow(true));
    }

    return (
        <SubscribersContext.Provider
            value={{ data, setData, loading, setLoading, message, setMessage, email, setEmail, subject, setSubject,
                show, setShow, load, setLoad, openDel, setOpenDel, handleDelete, handleCopy, id, setID
            }}>
            {children}
        </SubscribersContext.Provider>
    );
}
