import React from "react";
import { Avatar } from "@mui/material";

function CustomAvatar({
  avatar,
  name,
  imageStyle,
  handleClick,
  child,
}) {
  return (
    <>
      <Avatar
        alt={name}
        src={avatar || undefined} 
        sx={imageStyle}
        onClick={() => handleClick()}
      >
        {child}
      </Avatar>
    </>
  );
}

export default CustomAvatar;
