import React, { useEffect } from 'react'
import {
    Box,
    IconButton, Skeleton,
    Tooltip,
    Typography,
} from "@mui/material";
import { PRIMARY_COLOR_DarkNavyBlue } from "../../constants/Colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import StackCustom from "../../general_components/StackCustom";
import { SocialIconContext } from "../../contexts/SocialIconsContext";
import SocialLinkModal from './components/SocialLinkModal';
import SocialLinkCard from "./components/SocialLinkCard";

const SocialLinks = () => {
  
  const [open, setOpen] = React.useState(false);
  const { handleAddLink, handleDeleteLink, allLinks, fetchLinks, loadingLinks,
    openDel, setOpenDel, setClickedLinkId, handleEmpty,setEditMode,editMode,
    handleAddData } = React.useContext(SocialIconContext);

  const handleOpen = () => {
    setOpen(true);
    setEditMode(false)
  };

  const handleClose = () => {
    setOpen(false);
    handleEmpty()
  };
  
  useEffect(() => {
    fetchLinks()
  },[])

 
  return (
    <>
      <Box>
        <StackCustom
          text={"Links"}
          children={
            <Tooltip title="Add Link">
              <IconButton
                style={{
                  backgroundColor: PRIMARY_COLOR_DarkNavyBlue,
                  color: "#fff",
                  marginLeft: 6,
                }}
                variant={"contained"}
                onClick={handleOpen}
              >
                <AddRoundedIcon size={18} />
              </IconButton>
            </Tooltip>
          }
        />
        <div
          style={{
            paddingRight: "2vw",
            display: "flex",
            gap: "20px",
            marginTop: "30px",
            flexWrap: "wrap",
          }}
        >
          {
              loadingLinks ?
                  <>
                      <Skeleton width={'280px'} height={'120px'} />
                      <Skeleton width={'280px'} height={'120px'} />
                      <Skeleton width={'280px'} height={'120px'} />
                  </>
                  :
                  Array.isArray(allLinks) && allLinks.length > 0 ? (
                      allLinks.map((item, index) => (
                          <SocialLinkCard key={`LinkCard${index}`} item={item}
                          handleDelete={handleDeleteLink} openDel={openDel}
                          setOpenDel={setOpenDel} setEditMode={setEditMode} setClickedLinkId={setClickedLinkId}
                          handleEmpty={handleEmpty} handleAddData={handleAddData}
                          />
                      ))
                  ) : <Typography variant={'h6'}>No Links Found</Typography>
          }
        </div>
      </Box>
      <SocialLinkModal
        open={open} handleClose={handleClose} handlerFunction={handleAddLink}
        title="Add Link" text="Add Link" setOpen={setOpen} editMode={editMode}
      />
    </>
  );
}

export default SocialLinks