import React, { useContext, useEffect } from "react";
import { Container } from "@mui/material";
import { PeoplesContext } from "../../contexts/PeoplesContext";
import Page from "../../general_components/Page";
import PeopleSections from "./components/PeopleSections";
import DirectorsModel from "./components/DirectorsModel";
import LeadershipTeamModel from "./components/LeadershipTeamModel";
import LocalTeamModel from "./components/LocalTeamModel";

const Peoples = () => {
  const [openDirectorModel, setOpenDirectorModel] = React.useState(false);
  const [openLeadershipModel, setOpenLeadershipModel] = React.useState(false);
  const [openLocalModel, setOpenLocalModel] = React.useState(false);
  const projectContext = useContext(PeoplesContext);
  const {
    fetchDirectors, fetchLeadership, fetchLocalTeam, directors,
    leadershipTeam, localTeam, handleAddDirectors, handleAddLeadershipTeam,
    handleAddLocalTeam,
  } = projectContext;

  useEffect(() => {
    fetchDirectors();
    fetchLeadership();
    fetchLocalTeam();
  }, []);

  const handleCloseDirector = () => {
    setOpenDirectorModel(false);
  };
  const handleCloseLeadership = () => {
    setOpenLeadershipModel(false);
  };
  const handleCloseLocal = () => {
    setOpenLocalModel(false);
  };

  return (
    <Page title="Blutech Consulting | Peoples">
      <Container>
        <PeopleSections
          title="Directors"
          team={directors}
          setPeoplefunction={setOpenDirectorModel}
        />
        <PeopleSections
          title="Leadership Team"
          team={leadershipTeam}
          setPeoplefunction={setOpenLeadershipModel}
        />
        <PeopleSections
          title="Local Team"
          team={localTeam}
          setPeoplefunction={setOpenLocalModel}
        />
      </Container>
      <DirectorsModel
        type="add"
        open={openDirectorModel}
        handleClose={handleCloseDirector}
        handlerFunction={handleAddDirectors}
      />
      <LeadershipTeamModel
        type="add"
        open={openLeadershipModel}
        handleClose={handleCloseLeadership}
        handlerFunction={handleAddLeadershipTeam}
      />
      <LocalTeamModel
        type="add"
        open={openLocalModel}
        handleClose={handleCloseLocal}
        handlerFunction={handleAddLocalTeam}
      />
    </Page>
  );
};

export default Peoples;
