import { IconButton, Tooltip, Typography } from '@mui/material';
import React, { useContext } from 'react'
import { PRIMARY_COLOR_DarkNavyBlue } from '../../../constants/Colors';
import { makeStyles } from "@mui/styles";
import { FiPlus } from "react-icons/fi";
import PeoplesList from './PeoplesList';
import { PeoplesContext } from '../../../contexts/PeoplesContext';


const useStyles = makeStyles({
  titleContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  itemTitleText: {
    lineHeight: 0.5,
    marginRight: 5,
  },
});


const PeopleSections = ({ title, team, setPeoplefunction }) => {
    const classes = useStyles(); 
    const { setHoverSection, hoverSection } = useContext(PeoplesContext);
  return (
    <>
      <div
        onMouseOver={() => {
          setHoverSection(title);
        }}
        onMouseLeave={() => {
          setHoverSection("");
        }}
      >
        <div className={classes.titleContainer}>
          <Typography
            variant={"h5"}
            className={classes.itemTitleText}
            id={"title1"}
          >
            {title}
          </Typography>
          <Tooltip title={`Add ${hoverSection}`}>
            <IconButton
              style={{
                backgroundColor: PRIMARY_COLOR_DarkNavyBlue,
                color: "#fff",
                marginLeft: 5,
              }}
              variant={"contained"}
              onClick={() => {
                setPeoplefunction(true);
              }}
            >
              <FiPlus size={18} />
            </IconButton>
          </Tooltip>
        </div>
        <PeoplesList team={team} />
      </div>
    </>
  );
};

export default PeopleSections