import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import CustomAvatar from "../../../general_components/CustomAvatar";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getAPIEndpointUrl } from "../../../utils/ServerRequestModifier";

const useStyles = makeStyles({
  image: {
    width: "100%",
    height: 150,
    minHeight: 150,
    borderRadius: 15,
    position: "absolute",
    backgroundColor: "#eee",
    objectFit: "cover",
    resizeMode: "contain",
  },
});

export default function AddProfileImage({
  teamProfileRawImage,
  setTeamProfileRawImage,
  teamProfileImage,
  setTeamProfileImage,
  isNewsFile,
  setIsNewsFile,
  editMode,
  setEditMode,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const ref = React.useRef(null);

  const getProfileImageFile = async (e1, name) => {
    var file = e1.target.files[0];
    if (file !== undefined) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        fileType: "JPEG",
      };

      try {
        var fr = new FileReader();
        fr.onload = function (e) {
          setTeamProfileRawImage({
            image: e.target.result,
            file: new File([file], "image.jpeg"),
          });
          setTeamProfileImage(e.target.result);
        };
        fr.readAsDataURL(file);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      <Typography
        textAlign={"center"}
        variant="body2"
        sx={{ color: theme.palette.text.secondary }}
      >
        Profile Image
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: isNewsFile ? "center" : "flex-start",
        }}
      >
        <input
          ref={ref}
          style={{ display: "none" }}
          onChange={(e) => getProfileImageFile(e)}
          type={"file"}
        />
        <div style={{ position: "relative" }}>
          <CustomAvatar
            avatar={
              editMode
                ? getAPIEndpointUrl() + teamProfileImage
                : teamProfileImage
            }
            imageStyle={{
              width: isNewsFile ? 200 : 200,
              height: isNewsFile ? 150 : 150,
              minHeight: isNewsFile ? 100 : 100,
              borderRadius: "5px",
              objectFit: "cover",
              cursor: "pointer",
            }}
            handleClick={() => {
              ref.current.click();
              setEditMode(false);
            }}
            alphaImageStyle={{ width: 100, height: 100, bgcolor: "#FFFFFF" }}
            child={<InsertPhotoIcon sx={{ fontSize: 60 }} />}
          />
        </div>
      </div>
    </div>
  );
}
