import React, { createContext, useState } from "react";
import IndexServices from "../services/IndexServices";
import Cookies from "js-cookie";
import Loader from "../general_components/Loader";
import SuccessMessage from "../general_components/SuccessMessage";
import ErrorMessage from "../general_components/ErrorMessage";
import { convertFromRaw, EditorState } from "draft-js";

export const PeoplesContext = createContext();

export default function PeoplesContextProvider({ children }) {
  //   others
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedPeopleSection, setSelectedPeopleSection] = useState("");
  const [openDel, setOpenDel] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [clickedTeamId, setClickedTeamId] = useState(null);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [hoverSection, setHoverSection] = useState("");

  const [name, setName] = useState("");
  const [teamProfileImage, setTeamProfileImage] = useState("");
  const [teamProfileRawImage, setTeamProfileRawImage] = useState("");
  const [designation, setDesignation] = useState("");
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [directors, setDirectos] = useState([]);
  const [leadershipTeam, setLeadershipTeam] = useState([]);
  const [localTeam, setLocalTeam] = useState([]);
  const storedRole = Cookies.get("role");


  //   directors
  // fetch directors
  const fetchDirectors = async () => {
    try {
      const response = await IndexServices.getDirectors();
      setDirectos(response?.data?.data);
    } catch (error) {
      setError(true);
      setErrorMessage("Something Went Wrong");
    }
  };

  // add directors
    const handleAddDirectors = async (newTeam) => {
    setOpen(true);
    setLoading(true);
    const formData = new FormData();
    formData.append("name", newTeam.name);
    formData.append("designation", newTeam.designation);
    formData.append("description", newTeam.description);
    formData.append("image", newTeam.image);
    formData.append("isActive", false);
    await IndexServices.addDirectors(formData)
      .then((response) => {
        if (response.data.success) {
          fetchDirectors();
          setOpen(false);
          setConfirm(true);
          setTimeout(() => {
            setConfirm(false);
          }, 2000);
        } else {
          setError(true);
          setErrorMessage("Something Went Wrong");
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        setErrorMessage("Something Went Wrong");
      });
    handleEmpty();
  };

  // edit Team
  const handleEditDirectors = async (updatedTeamData, Id, oldTeamData) => {
    setOpen(true);
    setLoading(true);
    const formData = new FormData();
    if (
      updatedTeamData.name !== oldTeamData.name ||
      updatedTeamData.name !== ""
    ) {
      formData.append("name", updatedTeamData.name);
    }
    if (
      updatedTeamData.designation !== oldTeamData.designation ||
      updatedTeamData.designation !== ""
    ) {
      formData.append("designation", updatedTeamData.designation);
    }
    if (
      updatedTeamData.description !== oldTeamData.description ||
      updatedTeamData.description !== ""
    ) {
      formData.append("description", updatedTeamData.description);
    }
    if (updatedTeamData.image) {
      formData.append("image", updatedTeamData.image);
    }
    await IndexServices.editDirectors(Id, formData)
      .then((response) => {
        if (response.data.success) {
          fetchDirectors();
          setOpen(false);
          setConfirm(true);
          setTimeout(() => {
            setConfirm(false);
          }, 2000);
        } else {
          setError(true);
          setErrorMessage("Something Went Wrong");
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        setErrorMessage("Something Went Wrong");
      });
    handleEmpty();
  };

  //  delete team
  const handleDeleteDirectors = async () => {
    setOpen(true);
    setLoading(true);
    await IndexServices.deleteDirectors(clickedTeamId, storedRole)
      .then((response) => {
        if (response.data.success) {
          fetchDirectors();
          setOpen(false);
          setConfirm(true);
          setTimeout(() => {
            setConfirm(false);
          }, 2000);
        } else {
          setError(true);
          setErrorMessage("Something Went Wrong");
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        setErrorMessage("Something Went Wrong");
      });
  };

  //   Leadership
  // fetch leadership
  const fetchLeadership = async () => {
    try {
      const response = await IndexServices.getLeadershipTeam();
      setLeadershipTeam(response?.data?.data);
    } catch (error) {
      setError(true);
      setErrorMessage("Something Went Wrong");
    }
  };

  // add Leadership
    const handleAddLeadershipTeam = async (newTeam) => {
    setOpen(true);
    setLoading(true);
    const formData = new FormData();
    formData.append("name", newTeam.name);
    formData.append("designation", newTeam.designation);
    formData.append("description", newTeam.description);
    formData.append("image", newTeam.image);
    formData.append("isActive", false);
    await IndexServices.addLeadershipTeam(formData)
      .then((response) => {
        if (response.data.success) {
          fetchLeadership();
          setOpen(false);
          setConfirm(true);
          setTimeout(() => {
            setConfirm(false);
          }, 2000);
        } else {
          setError(true);
          setErrorMessage("Something Went Wrong");
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        setErrorMessage("Something Went Wrong");
      });
    handleEmpty();
  };

  // edit Leadership
  const handleEditLeadershipTeam = async (updatedTeamData, Id, oldTeamData) => {
    setOpen(true);
    setLoading(true);
    const formData = new FormData();
    if (
      updatedTeamData.name !== oldTeamData.name ||
      updatedTeamData.name !== ""
    ) {
      formData.append("name", updatedTeamData.name);
    }
    if (
      updatedTeamData.designation !== oldTeamData.designation ||
      updatedTeamData.designation !== ""
    ) {
      formData.append("designation", updatedTeamData.designation);
    }
    if (
      updatedTeamData.description !== oldTeamData.description ||
      updatedTeamData.description !== ""
    ) {
      formData.append("description", updatedTeamData.description);
    }
    if (updatedTeamData.image) {
      formData.append("image", updatedTeamData.image);
    }
    await IndexServices.editLeadershipTeam(Id, formData)
      .then((response) => {
        if (response.data.success) {
          fetchLeadership();
          setOpen(false);
          setConfirm(true);
          setTimeout(() => {
            setConfirm(false);
          }, 2000);
        } else {
          setError(true);
          setErrorMessage("Something Went Wrong");
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        setErrorMessage("Something Went Wrong");
      });
    handleEmpty();
  };

  //  delete Leadership
  const handleDeleteLeadership = async () => {
    setOpen(true);
    setLoading(true);
    await IndexServices.deleteLeadershipTeam(clickedTeamId, storedRole)
      .then((response) => {
        if (response.data.success) {
          fetchLeadership();
          setOpen(false);
          setConfirm(true);
          setTimeout(() => {
            setConfirm(false);
          }, 2000);
        } else {
          setError(true);
          setErrorMessage("Something Went Wrong");
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        setErrorMessage("Something Went Wrong");
      });
  };

  //  LocalTeam
  // fetch Local team
  const fetchLocalTeam = async () => {
    try {
      const response = await IndexServices.getLocalTeam();
      setLocalTeam(response?.data?.data);
    } catch (error) {
      setError(true);
      setErrorMessage("Something Went Wrong");
    }
  };

  // add Local Team
  const handleAddLocalTeam = async (newTeam) => {
    setOpen(true);
    setLoading(true);
    const formData = new FormData();
    formData.append("name", newTeam.name);
    formData.append("designation", newTeam.designation);
    formData.append("description", newTeam.description);
    formData.append("image", newTeam.image);
    formData.append("isActive", false);
    await IndexServices.addLocalTeam(formData)
      .then((response) => {
        if (response.data.success) {
          fetchLocalTeam();
          setOpen(false);
          setConfirm(true);
          setTimeout(() => {
            setConfirm(false);
          }, 2000);
        } else {
          setError(true);
          setErrorMessage("Something Went Wrong");
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        setErrorMessage("Something Went Wrong");
      });
    handleEmpty();
  };

  // edit Local team
  const handleEditLocalTeam = async (updatedTeamData, Id, oldTeamData) => {
    setOpen(true);
    setLoading(true);
    const formData = new FormData();
    if (
      updatedTeamData.name !== oldTeamData.name ||
      updatedTeamData.name !== ""
    ) {
      formData.append("name", updatedTeamData.name);
    }
    if (
      updatedTeamData.designation !== oldTeamData.designation ||
      updatedTeamData.designation !== ""
    ) {
      formData.append("designation", updatedTeamData.designation);
    }
    if (
      updatedTeamData.description !== oldTeamData.description ||
      updatedTeamData.description !== ""
    ) {
      formData.append("description", updatedTeamData.description);
    }
    if (updatedTeamData.image) {
      formData.append("image", updatedTeamData.image);
    }
    await IndexServices.editLocalTeam(Id, formData)
      .then((response) => {
        if (response.data.success) {
          fetchLocalTeam();
          setOpen(false);
          setConfirm(true);
          setTimeout(() => {
            setConfirm(false);
          }, 2000);
        } else {
          setError(true);
          setErrorMessage("Something Went Wrong");
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        setErrorMessage("Something Went Wrong");
      });
    handleEmpty();
  };

  //  delete Local Team
  const handleDeleteLocalTeam = async () => {
    setOpen(true);
    setLoading(true);
    await IndexServices.deleteLocalTeam(clickedTeamId, storedRole)
      .then((response) => {
        if (response.data.success) {
          fetchLocalTeam();
          setOpen(false);
          setConfirm(true);
          setTimeout(() => {
            setConfirm(false);
          }, 2000);
        } else {
          setError(true);
          setErrorMessage("Something Went Wrong");
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        setErrorMessage("Something Went Wrong");
      });
  };

  const handleEmpty = () => {
    setName("");
    setTeamProfileImage("");
    setDesignation("");
    setDescription(null);
  };

  const handleAddData = (data) => {
    setName(data.name);
    setTeamProfileImage(data.image);
    setDesignation(data.designation);
    setDescription(
      !data.description
        ? EditorState.createEmpty()
        : EditorState.createWithContent(
            convertFromRaw(JSON.parse(data.description))
          )
    );
  };

  return (
    <PeoplesContext.Provider
      value={{
        fetchDirectors,
        handleAddDirectors,
        handleEditDirectors,
        handleDeleteDirectors,
        handleDeleteLeadership,
        handleEditLeadershipTeam,
        handleAddLeadershipTeam,
        fetchLeadership,
        handleDeleteLocalTeam,
        fetchLocalTeam,
        handleAddLocalTeam,
        handleEditLocalTeam,
        open,
        setOpen,
        confirm,
        setConfirm,
        name,
        setName,
        teamProfileImage,
        setTeamProfileImage,
        designation,
        setDesignation,
        description,
        setDescription,
        teamProfileRawImage,
        setTeamProfileRawImage,
        handleEmpty,
        handleAddData,
        openDel,
        setOpenDel,
        clickedTeamId,
        setClickedTeamId,
        load,
        setLoad,
        editMode,
        setEditMode,
        directors,hoverSection, setHoverSection,
        leadershipTeam,
        localTeam, 
        selectedPeopleSection, setSelectedPeopleSection, 
      }}
    >
      {children}
      <Loader open={open} close={setOpen} />
      <SuccessMessage open={confirm} close={setConfirm} />
      <ErrorMessage open={error} close={setError} message={errorMessage} />
    </PeoplesContext.Provider>
  );
}
