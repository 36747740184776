import {React, createContext, useState, useEffect} from "react";
import IndexServices from "../services/IndexServices";
import Loader from "../general_components/Loader";
import SuccessMessage from "../general_components/SuccessMessage";
import ErrorMessage from "../general_components/ErrorMessage";

export const ContactsContext = createContext();
const filterList = [
  {id: "today", label: "Today",},
  {id: "yesterday", label: "Yesterday",},
  {id: "thisweek", label: "This Week",},
  {id: "alltime", label: "All Time",},
];

export default function ContactsContextProvider({ children }) {

  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [contacts, setContacts] = useState([]);
  const [clickedUser, setClickedUser] = useState(null);
  const [filter, setFilter] = useState("All Time");
  const OnClickedUser = contacts?.filter((item) => {
    return item.contactId === clickedUser;
  });

  const fetchContact = async () => {
    try {
      const response = await IndexServices.getContacts();
      console.log("contact", response?.data?.data);
      setContacts(response?.data?.data);
    } catch (error) {
      setError(true);
      setErrorMessage("Something Went Wrong");
    }
  };

  useEffect(() => {
    fetchContact();
  }, []);

  const varifyId = (Id) => {
    return OnClickedUser[0]?.contactId === Id;
  };

  const filterContacts = (filterText) => {
    switch (filterText) {
      case "Today":
        const today = new Date();
        return contacts.filter((item) => {
          const itemDate = new Date(item.date);
          return (
            itemDate.getDate() === today.getDate() &&
            itemDate.getMonth() === today.getMonth() &&
            itemDate.getFullYear() === today.getFullYear()
          );
        });

      case "Yesterday":
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return contacts.filter((item) => {
          const itemDate = new Date(item.date);
          return (
            itemDate.getDate() === yesterday.getDate() &&
            itemDate.getMonth() === yesterday.getMonth() &&
            itemDate.getFullYear() === yesterday.getFullYear()
          );
        });

      case "This Week":
        const newtoday = new Date();
        const startOfWeek = new Date(newtoday);
        startOfWeek.setDate(newtoday.getDate() - newtoday.getDay());

        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);

        return contacts.filter((item) => {
          const itemDate = new Date(item.date);
          return itemDate >= startOfWeek && itemDate <= endOfWeek;
        });

      case "All Time":
        return contacts;

      default:
        return contacts;
    }
  };

  const filteredUser = filterContacts(filter);

  return (
    <ContactsContext.Provider
      value={{
        contacts,
        clickedUser,
        setClickedUser,
        OnClickedUser,
        setFilter,
        filteredUser,
        filter,
        filterList,
        varifyId,
        fetchContact,
      }}
    >
      {children}
      <Loader open={open} close={setOpen} />
      <SuccessMessage open={confirm} close={setConfirm} />
      <ErrorMessage open={error} close={setError} message={errorMessage} />
    </ContactsContext.Provider>
  );
}
