import { getAPIEndpointUrl } from "./ServerRequestModifier";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaWikipediaW,
} from "react-icons/fa";

export function getShortDate(fullDate) {
    const dateObj = new Date(fullDate);
  
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('default', { month: 'short' });
  
    return `${day} ${month}`;
}
  
export function formatDate(inputDate) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(inputDate);
  return date.toLocaleDateString(undefined, options);
}

export const extractUsernameFromUrl = (url) => {
  try {
    const pathnameParts = new URL(url).pathname.split("/");
    let username = null;

    // Check for common social media patterns
    if (pathnameParts.includes("company")) {
      // LinkedIn pattern
      const usernameIndex = pathnameParts.indexOf("company") + 1;
      username = pathnameParts[usernameIndex];
    } else if (pathnameParts.includes("facebook.com")) {
      // Facebook pattern
      const usernameIndex = pathnameParts.indexOf("facebook.com") + 1;
      username = pathnameParts[usernameIndex];
    } else if (pathnameParts.includes("youtube.com")) {
      // YouTube pattern
      const usernameIndex = pathnameParts.indexOf("youtube.com") + 1;
      username = pathnameParts[usernameIndex];
    } else {
      username = new URL(url).pathname.split("/").pop();
      return username;
    }
    return username;
  } catch (error) {
    console.error("Invalid URL:", error.message);
    return null;
  }
};

export const ChangeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function getSocialMediaIconandColor(platform) {
  const socialMediaIcons = [
    {
      platform: "facebook",
      icon: <FaFacebook size={30} color="#1877f2" />,
      color: "#1877f2",
      link: "https://www.facebook.com/",
      path: "facebook.com/",
    },
    {
      platform: "twitter",
      icon: <FaTwitter size={30} color="#1da1f2" />,
      color: "#1da1f2",
      link: "https://www.twitter.com/",
      path: "twitter.com/",
    },
    {
      platform: "instagram",
      icon: <FaInstagram size={30} color="#bc2a8d" />,
      color: "#bc2a8d",
      link: "https://www.instagram.com/",
      path: "instagram.com/",
    },
    {
      platform: "linkedin",
      icon: <FaLinkedin size={30} color="#0077b5" />,
      color: "#0077b5",
      link: "https://www.linkedin.com/company/",
      path: "linkedin.com/company/",
    },
    {
      platform: "youtube",
      icon: <FaYoutube size={30} color={"#FF0000"} />,
      color: "#FF0000",
      link: "https://www.youtube.com/",
      path: "youtube.com/",
    },
    {
      platform: "other",
      icon: <FaWikipediaW size={30} color="#636466" />,
      color: "#636466",
      link: "",
      path: "",
    },
  ];

  const platformObject = socialMediaIcons.find(
    (item) => item.platform === platform
  );

  if (platformObject) {
    return platformObject;
  } else {
    return null;
  }
}

export const getLocalReplaced = (file) => {
    return file.replace('http://localhost:3001', getAPIEndpointUrl())
}