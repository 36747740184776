import React from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, Card, IconButton, Tooltip, Typography } from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { alpha } from "@mui/material/styles";
import {
    PRIMARY_COLOR_DarkNavyBlue,
    PRIMARY_COLOR_LightSkyBlue,
    PRIMARY_COLOR_MediumRoyalBlue, SECONDARY_COLOR_DarkMaroon,
    WHITE
} from "../../../constants/Colors";
import ConfirmationDialog from "../../../general_components/ConformationDialog";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 5,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "150px",
    display: "block",
    overflow: "hidden",
  },
  text: {
    opacity: 0.8,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "150px",
    display: "block",
    overflow: "hidden",
  },
  buttonContainer: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}));

export default function AdminCard(props) {

    const { data, index, setSelectedAdminId,
        load, openDel, setOpenDel, handleDelete, storedRole,
        storedEmail } = props
  const classes = useStyles();
  const [show, setShow] = React.useState(-1);

  return (
    <>
      {" "}
      <Card
        sx={{
          // mt: 4,
          py: 5,
          boxShadow: 0,
          borderRadius: 5,
          textAlign: "center",
          height: "35vh",
          position: "relative",
          color: WHITE,
          backgroundColor: PRIMARY_COLOR_MediumRoyalBlue,
          backgroundImage: (theme) =>
            `linear-gradient(110deg, ${alpha(
              PRIMARY_COLOR_MediumRoyalBlue,
              0.2
            )} 0%, ${alpha(PRIMARY_COLOR_MediumRoyalBlue, 0.34)} 100%)`,
        }}
        onMouseOver={() => setShow(index)}
        onMouseLeave={() => setShow(-1)}
      >
        <div className={classes.buttonContainer}>
          {!(show === index) ? null : storedRole === 'Admin' ? null : storedEmail === data.email ? null : (
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  setSelectedAdminId(data.userId);
                  setOpenDel(true);
                }}
              >
                <DeleteRoundedIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              width: 60,
              height: 60,
              marginBottom: 2,
              color: "#e1e1e1",
              backgroundColor: PRIMARY_COLOR_MediumRoyalBlue,
              backgroundImage: (theme) =>
                `linear-gradient(150deg, ${alpha(
                    PRIMARY_COLOR_LightSkyBlue,
                  10
                )} 15%, ${alpha(SECONDARY_COLOR_DarkMaroon, 1.24)} 100%)`,
            }}
            src={data.avatar}
          />
          <Typography
            variant={"body1"}
            sx={{ fontWeight: 700 }}
            className={classes.title}
          >
            {data.name}
          </Typography>
          <Typography variant={"body2"} className={classes.text}>
            {data.email}
          </Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.72, paddingTop: 2 }}>
              {data.role === "Super_Admin" ? "Super Admin" : "Admin"}
          </Typography>
        </div>
      </Card>
      <ConfirmationDialog
        loading={load}
        open={openDel}
        close={setOpenDel}
        confirm={handleDelete}
        title="Confirmation"
        message="Are you sure you want to delete this?"
        cancelBtnText="Cancel"
        confirmBtnText="Delete"
        pageName="admin"
      />
    </>
  );
}
