import Admins from "../pages/admins/Admins";
import Candidates from "../pages/candidates/Candidates";
import Careers from "../pages/careers/Careers";
import Contact from "../pages/contact/Contact";
import Home from "../pages/home/Home";
import News from "../pages/news/News";
import Peoples from "../pages/peoples/Peoples";
import Subscribers from "../pages/subscribers/Subscribers";


export const AuthRoutes = [
  // {
  //     path: '/dashboard', component: <DashboardContextProvider> <Dashboard/> </DashboardContextProvider>
  // },
  {
    path: "/home",
    component: <Home />,
  },
  {
    path: "/contactus",
    component: <Contact />,
  },
  {
    path: "/careers",
    component: <Careers />,
  },
  {
    path: "/careers/candidates/:jobId",
    component: <Candidates/>,
  },
  // {
  //   path: "/news",
  //   component: <News />,
  // },
  {
    path: "/peoples",
    component: <Peoples />,
  },
  {
    path: "/admins",
    component: <Admins />,
  },
  // {
  //   path: "/subscribers",
  //   component: <Subscribers />,
  // },
];
