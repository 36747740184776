import React, { useState } from "react";
import IndexServices from "../services/IndexServices";
import ErrorMessage from "../general_components/ErrorMessage";
import Cookies from "js-cookie";
import Loader from "../general_components/Loader";
import SuccessMessage from "../general_components/SuccessMessage";

export const AdminsContext = React.createContext();

export default function AdminsContextProvider({ children }) {
   const [error, setError] = useState(false);
   const [errorMessage, setErrorMessage] = useState("");
   const [selectedAdminId, setSelectedAdminId] = useState("");
   const [admins, setAdmins] = useState([]);
   const storedRole = Cookies.get("role");
   const [open, setOpen] = useState(false);
   const [confirm, setConfirm] = useState(false);
   const [openDel, setOpenDel] = useState(false);
   const [loading, setLoading] = useState(false);

  //Loading
  const [load, setLoad] = useState(false);

  //Confirmation Open-Close
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [iconStyled, setIconStyled] = useState(false);

  //Which Item
  const [index, setIndex] = useState(-1);

  // fetch Category
  const fetchAdmins = async () => {
    try {
      const response = await IndexServices.getAdmins();
      setAdmins(response?.data?.data);
    } catch (error) {
      setError(true);
      setErrorMessage("Something Went Wrong");
    }
  };

  //Add Admins
  const [openAddAdmins, setOpenAddAdmins] = useState(false);
  const [name, setName] = useState(""),
    [email, setEmail] = useState(""),
    [password, setPassword] = useState(""),
    [logo, setLogo] = useState(""),
    [adminType, setAdminType] = useState(null);


    function handleDelete() {
      IndexServices.deleteAdmin(selectedAdminId, { role: storedRole })
        .then((response) => {
          if(response.data.success) {
            setAdmins(admins.filter((item) => item.userId !== selectedAdminId))
            setOpen(false);
            setConfirm(true);
            setTimeout(() => {
              setConfirm(false);
            }, 2000);
          } else {
            setError(true);
            setErrorMessage("Something Went Wrong");
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          setError(true);
          setErrorMessage(e);
        });
    }
  
  const handleEmpty = () => {
    setName("");
    setEmail("");
    setPassword("");
    setAdminType(null);
  };

  return (
    <AdminsContext.Provider
      value={{
        admins,
        setAdmins,
        openConfirmation,
        setOpenConfirmation,
        handleDelete,
        index,
        setIndex,
        load,
        setLoad,
        openAddAdmins,
        setOpenAddAdmins,
        logo,
        setLogo,
        name,
        setName,
        email,
        setEmail,
        password,
        setPassword,
        adminType,
        setAdminType,
        iconStyled,
        setIconStyled,
        fetchAdmins,
        handleEmpty,
        setSelectedAdminId,
        openDel,
        setOpenDel,
        loading,
        setLoading,
      }}
    >
      {children}
      <Loader open={open} close={setOpen} />
      <SuccessMessage open={confirm} close={setConfirm} />
      <ErrorMessage open={error} close={setError} message={errorMessage} />
    </AdminsContext.Provider>
  );
}
