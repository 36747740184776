import {Avatar, Box, IconButton, Stack, Tooltip, Typography,} from "@mui/material";
import React, { useContext } from "react";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { useTheme } from "@mui/material/styles";
import { GRAY_LIGHT, PRIMARY_COLOR_MediumRoyalBlue,} from "../../../constants/Colors";
import draftToHtml from "draftjs-to-html";
import { makeStyles } from "@mui/styles";
import { getAPIEndpointUrl } from "../../../utils/ServerRequestModifier";
import DirectorsModel from "./DirectorsModel";
import LeadershipTeamModel from "./LeadershipTeamModel";
import LocalTeamModel from "./LocalTeamModel";
import { PeoplesContext } from "../../../contexts/PeoplesContext";
import ConfirmationDialogForDirector from "./ConfirmationDialogForDirector";
import ConfirmationDialogForLeadership from "./ConfirmationDialogForLeadership";
import ConfirmationDialogForLocal from "./ConformationDialogForLocal";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 1,
  },
}));

export default function PeoplesCard({ item, categoryName, categoryId }) {
  const projectContext = useContext(PeoplesContext);
  const [openDirectorEditModel, setOpenDirectorEditModel] = React.useState(false);
  const [openLeadershipEditModel, setOpenLeadershipEditModel] = React.useState(false);
  const [openLocalEditModel, setOpenLocalEditModel] = React.useState(false);
  const [oldTeamData, setOldTeamData] = React.useState("");
  const [openDelDirector, setOpenDelDirector] = React.useState(false);
  const [openDelLeadership, setOpenDelLeadership] = React.useState(false);
  const [openDelLocal, setOpenDelLocal] = React.useState(false);
  const {
    handleEditDirectors,
    handleDeleteDirectors,load,
    handleEditLeadershipTeam, handleEmpty,
    handleDeleteLeadership,handleAddData,
    handleEditLocalTeam,setClickedTeamId,clickedTeamId,
    handleDeleteLocalTeam,hoverSection, setEditMode,
  } = projectContext;
  const classes = useStyles();
  const theme = useTheme();
  const [show, setShow] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  React.useEffect(() => {
    try {
      document.getElementById(item._id).innerHTML = draftToHtml(
        JSON.parse(item.description)
      );
    } catch (e) {}
  }, [item]);

   const handleCloseEditDirector = () => {
     setOpenDirectorEditModel(false);
     setClickedTeamId("")
     setEditMode(false)
     handleEmpty()
   };
   const handleCloseEditLeadership = () => {
     setOpenLeadershipEditModel(false);
     setClickedTeamId("")
     setEditMode(false);
     handleEmpty();
   };
   const handleCloseEditLocal = () => {
     setOpenLocalEditModel(false);
     setClickedTeamId("")
     setEditMode(false);
     handleEmpty();
   };
  return (
    <>
      <Box
        sx={{
          width: 240,
          height: 350,
          position: "relative",
        }}
        onMouseOver={() => setShow(item.id)}
        onMouseLeave={() => setShow(-1)}
      >
        <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className={classes.buttonContainer}>
              {!(show === item.id) ? null : (
                <>
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => {
                        if (hoverSection === "Directors") {
                          setOpenDelDirector(true);
                          setClickedTeamId(item.directorTeamId);
                        } else if (hoverSection === "Leadership Team") {
                          setOpenDelLeadership(true);
                          setClickedTeamId(item.leadershipTeamId);
                        } else if (hoverSection === "Local Team") {
                          setOpenDelLocal(true);
                          setClickedTeamId(item.localTeamId);
                        }
                      }}
                    >
                      <DeleteRoundedIcon
                        sx={{
                          fontSize: 20,
                          color: GRAY_LIGHT,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit">
                    <IconButton
                      onClick={() => {
                        if (hoverSection === "Directors") {
                          setOpenDirectorEditModel(true);
                          setOpenLeadershipEditModel(false);
                          setOpenLocalEditModel(false);
                          setClickedTeamId(item.directorTeamId);
                          setOldTeamData(item);
                          setEditMode(true);
                          handleAddData(item);
                        } else if (hoverSection === "Leadership Team") {
                          setOpenDirectorEditModel(false);
                          setOpenLeadershipEditModel(true);
                          setOpenLocalEditModel(false);
                          setClickedTeamId(item.leadershipTeamId);
                          setOldTeamData(item);
                          setEditMode(true);
                          handleAddData(item);
                        } else if (hoverSection === "Local Team") {
                          setOpenDirectorEditModel(false);
                          setOpenLeadershipEditModel(false);
                          setOpenLocalEditModel(true);
                          setClickedTeamId(item.localTeamId);
                          setOldTeamData(item);
                          setEditMode(true);
                          handleAddData(item);
                        }
                      }}
                    >
                      <CreateRoundedIcon
                        sx={{
                          fontSize: 20,
                          color: GRAY_LIGHT,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
            <Avatar
              sx={{
                width: "100%",
                height: 300,
                borderRadius: "0px",
                zIndex: 0,
              }}
              src={getAPIEndpointUrl() + item?.image}
            />
          </Box>
          <>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              marginTop={1}
            >
              <Typography
                variant="body1"
                color={PRIMARY_COLOR_MediumRoyalBlue}
                sx={{ fontWeight: 700 }}
              >
                {item?.name}
              </Typography>
              <Stack>
                <Typography
                  textAlign={"end"}
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontWeight: 600 }}
                >
                  {item?.designation}
                </Typography>
              </Stack>
            </Box>
          </>
        </div>
      </Box>
      <DirectorsModel
        type="edit"
        open={openDirectorEditModel}
        handleClose={handleCloseEditDirector}
        handlerFunction={handleEditDirectors}
        id={clickedTeamId}
        selectedIdFunction={setClickedTeamId}
        previousTeam={oldTeamData}
      />
      <LeadershipTeamModel
        type="edit"
        open={openLeadershipEditModel}
        handleClose={handleCloseEditLeadership}
        handlerFunction={handleEditLeadershipTeam}
        id={clickedTeamId}
        selectedIdFunction={setClickedTeamId}
        previousTeam={oldTeamData}
      />
      <LocalTeamModel
        type="edit"
        open={openLocalEditModel}
        handleClose={handleCloseEditLocal}
        handlerFunction={handleEditLocalTeam}
        id={clickedTeamId}
        selectedIdFunction={setClickedTeamId}
        previousTeam={oldTeamData}
      />
      <ConfirmationDialogForDirector
        loading={load}
        open={openDelDirector}
        close={setOpenDelDirector}
        confirm={handleDeleteDirectors}
        title="Confirmation"
        message="Are you sure you want to delete this?"
        cancelBtnText="Cancel"
        confirmBtnText="Delete"
        selectedId={setClickedTeamId}
      />
      <ConfirmationDialogForLeadership
        loading={load}
        open={openDelLeadership}
        close={setOpenDelLeadership}
        confirm={handleDeleteLeadership}
        title="Confirmation"
        message="Are you sure you want to delete this?"
        cancelBtnText="Cancel"
        confirmBtnText="Delete"
        selectedId={setClickedTeamId}
      />
      <ConfirmationDialogForLocal
        loading={load}
        open={openDelLocal}
        close={setOpenDelLocal}
        confirm={handleDeleteLocalTeam}
        title="Confirmation"
        message="Are you sure you want to delete this?"
        cancelBtnText="Cancel"
        confirmBtnText="Delete"
        selectedId={setClickedTeamId}
      />
    </>
  );
}
