import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import WorkIcon from '@mui/icons-material/Work';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { WHITE, WHITE_DIM } from "../constants/Colors";
import ContactsIcon from '@mui/icons-material/ContactsRounded';
import NewspaperIcon from '@mui/icons-material/NewspaperRounded';
import GroupsIcon from '@mui/icons-material/GroupsRounded';
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

const sidebarConfig = [
  // {
  //   title: 'Dashboard',
  //   path: '/dashboard',
  //   icon: <DashboardIcon/>
  // },
  // {
  //   title: "Home",
  //   path: "/home",
  //   icon: <HomeRoundedIcon/>,
  // },
  {
    title: "Contact",
    path: "/contactus",
    icon: <ContactsIcon/>,
  },
  {
    title: "Careers",
    path: "/careers",
    icon: <WorkIcon/>,
  },
  // {
  //   title: "News",
  //   path: "/news",
  //   icon: <NewspaperIcon />,
  // },
  // {
  //   title: 'Peoples',
  //   path: '/peoples',
  //   icon: <GroupsIcon />
  // },
  // {
  //   title: "Subscribers",
  //   path: "/subscribers",
  //   icon: <PersonAddAltRoundedIcon />,
  // },
  {
    title: "Admins",
    path: "/admins",
    icon: <AdminPanelSettingsIcon />,
  },
  // {
  //   title: 'Emails',
  //   path: '/emails',
  //   icon: <EmailIcon/>
  // },
];

export default sidebarConfig;
