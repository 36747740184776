import { Avatar, Box, Stack, Typography } from "@mui/material";
import React from "react";
import { getShortDate } from "../../../utils/Functions";
import { PRIMARY_COLOR_DarkNavyBlue } from "../../../constants/Colors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  cardBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "15px",
    backgroundColor: "#F3F4FF",
    width: "25vw",
    padding: "8px",
    
  },
  cardInnerBox: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  },
});

const ContactsCard = ({ item, varifyId }) => {

  const classes = useStyles();
  const shortDate = getShortDate(item.createdAt);
  const varifyStyled = varifyId(item.contactId);

  return (
    <Box className={classes.cardBox}>
      <Box className={classes.cardInnerBox}>
        <Avatar
          alt="Remy Sharp"
          src=""
          style={{
            backgroundColor: varifyStyled ? PRIMARY_COLOR_DarkNavyBlue : " ",
          }}
        />
        <Stack>
          <Typography
            sx={{
              fontWeight: "600",
              color: varifyStyled ? PRIMARY_COLOR_DarkNavyBlue : " ",
            }}
          >
            {item.name}
          </Typography>
          <Typography>{item.phone}</Typography>
        </Stack>
      </Box>
      <Typography>{shortDate}</Typography>
    </Box>
  );
};

export default ContactsCard;
