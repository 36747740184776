import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Typography} from "@mui/material";
import { BLACK,SECONDARY_COLOR_DarkMaroon } from "../constants/Colors";

export default function CustomDialog({title, open, handleClose, children}) {

    return (
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          <Typography
            variant={"body1"}
            style={{ color: BLACK, fontWeight: "600" }}
          >
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ paddingTop: 15 }}>
          {children}
        </DialogContent>
      </Dialog>
    );
}
