import React from "react";
import {
    ChangeFirstLetter,
    extractUsernameFromUrl,
    getSocialMediaIconandColor,
} from "../../../utils/Functions";
import { Box, Card, CardContent, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import { alpha, useTheme} from "@mui/material/styles";
import { makeStyles} from "@mui/styles";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { SocialIconContext } from "../../../contexts/SocialIconsContext";
import { PRIMARY_COLOR_MediumRoyalBlue } from "../../../constants/Colors";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import SocialLinkModal from "./SocialLinkModal";
import ConfirmationDialog from "../../../general_components/ConformationDialog";


const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}));

const SocialLinkCard = ({ handleDelete, item,setOpenDel,loading,openDel,setClickedLinkId, handleEmpty ,handleAddData,setEditMode }) => {
  const classes = useStyles();
  const theme = useTheme();
  const result = getSocialMediaIconandColor(item.plateform);
  const [show, setShow] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const {
    handleEditLink,
  } = React.useContext(SocialIconContext);

  const handleOpen = () => {
    setOpen(true);
    handleAddData(item)
  };

  const handleClose = () => {
    setOpen(false);
    handleEmpty();
  };

  return (
    <div>
      <Card
        sx={{
          width: "280px",
          position: "relative",
          borderRadius: "15px",
          bgcolor: alpha(
            PRIMARY_COLOR_MediumRoyalBlue,
            theme.palette.action.selectedOpacity
          ),
        }}
        onMouseOver={() => setShow(item.linkId)}
        onMouseLeave={() => setShow(-1)}
      >
        <div className={classes.buttonContainer}>
          {!(show === item.linkId) ? null : (
            <>
              <Tooltip title="Delete">
                <IconButton
                  onClick={() => {
                    setOpenDel(true);
                    setClickedLinkId(item.linkId);
                  }}
                >
                  <DeleteRoundedIcon
                    sx={{ fontSize: 20, color: theme.palette.text.secondary }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit">
                <IconButton
                  onClick={() => {
                    handleOpen(); setEditMode(true)
                  }}
                >
                  <CreateRoundedIcon
                    sx={{ fontSize: 20, color: theme.palette.text.secondary }}
                  />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
        <CardContent>
          <Box style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50px",
                width: "50px",
                height: "50px",
              }}
            >
              {result.icon}
            </div>
            <div>
              <Typography sx={{ color: result.color ? result.color : "" }}>
                {ChangeFirstLetter(item.plateform)}
              </Typography>
              <div style={{ maxWidth: "200px" }}>
                <Typography
                  sx={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {result?.path + extractUsernameFromUrl(item.link)}
                </Typography>
              </div>
            </div>
          </Box>
        </CardContent>
      </Card>
      <SocialLinkModal
        open={open} handleClose={handleClose} handlerFunction={handleEditLink}
        setOpen={setOpen} id={item.linkId} title="Edit Link" text="Update Link"
      />
      <ConfirmationDialog
        loading={loading} open={openDel} close={setOpenDel} confirm={handleDelete}
        title="Confirmation" message="Are you sure you want to delete this?"
        cancelBtnText="Cancel" confirmBtnText="Delete" pageName="socialLink"
      />
    </div>
  );
};

export default SocialLinkCard;
