import React, { useContext} from "react";
import {
  Container,
  Divider,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import Page from "../../general_components/Page";
import { makeStyles } from "@mui/styles";
import SubscribersList from "./components/List";
import ConfirmationDialog from "../../general_components/ConformationDialog";
import StackCustom from "../../general_components/StackCustom";
import Body from "./components/Body";
import { SubscribersContext } from "../../contexts/SubscribersContext";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

const useStyles = makeStyles({
  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  gridContainer: {
    display: "flex",
    maxWidth: "100%",
    minWidth: "100%",
  },
  details: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    paddingLeft: 10,
  },
});

function Subscribers() {
  const classes = useStyles();
  const {
    data,
    loading,
    setLoading,
    message,
    setMessage,
    email,
    setEmail,
    subject,
    setSubject,
    load,
    openDel,
    setOpenDel,
    handleDelete,
    handleCopy,
    setID,
  } = useContext(SubscribersContext);

  return (
    <Page title="Blutech Consulting | Subscribers">
      <Container>
        <div className={classes.gridContainer}>
          <div>
            <Box display={"flex"} mb={1}>
              <StackCustom text="Subscribers" />
              <Tooltip title="Copy All">
                <IconButton sx={{ marginLeft: "auto" }} onClick={handleCopy}>
                  <ContentCopyRoundedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <SubscribersList
              setOpen={setOpenDel}
              setID={setID}
              setEmail={setEmail}
              data={data}
            />
          </div>
          <Divider orientation="vertical" sx={{ height: "90vh" }}/>
          <div className={classes.details}>
            <StackCustom text="Email"/>
            <Box mb={1} ml={2}>
              <Body
                mailbox={true}
                loading={loading}
                setLoading={setLoading}
                email={email}
                setEmail={setEmail}
                message={message}
                setMessage={setMessage}
                subject={subject}
                setSubject={setSubject}
              />
            </Box>
          </div>
        </div>
      </Container>
      <ConfirmationDialog
        loading={load}
        open={openDel}
        close={setOpenDel}
        confirm={handleDelete}
        title="Confirmation"
        message="Are you sure you want to delete this?"
        cancelBtnText="Cancel"
        confirmBtnText="Delete"
      />
    </Page>
  );
}

export default Subscribers;
