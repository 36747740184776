import React, { createContext, useState } from "react";
import IndexServices from "../services/IndexServices";
import Cookies from "js-cookie";
import Loader from "../general_components/Loader";
import SuccessMessage from "../general_components/SuccessMessage";
import ErrorMessage from "../general_components/ErrorMessage";
import {extractUsernameFromUrl} from "../utils/Functions";

export const SocialIconContext = createContext();

export default function SocialIconContextProvider({ children }) {

    const SocialLinkArray = [
    "  ",
    "facebook",
    "twitter",
    "instagram",
    "linkedin",
  ];

  const [openDel, setOpenDel] = useState(false);
  const [clickedLinkId, setClickedLinkId] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingLinks, setLoadingLinks] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [Link, setLink] = useState("");
  const [allLinks, setAllLinks] = useState([]);
  const [socialPlatform, setSocialPlatform] = React.useState("");
  const storedRole = Cookies.get("role"); 

// fetch Links
  const fetchLinks = async () => {
      setLoadingLinks(true)
      try {
          const response = await IndexServices.getLinks();
          setAllLinks(response?.data?.data);
          setLoadingLinks(false)
      } catch (error) {
          setLoadingLinks(false)
          setError(true);
          setErrorMessage("Something Went Wrong");
      }
  };

  // add link
  const handleAddLink = async (LinkData) => {
    setOpen(true)
    setLoading(true);
    await IndexServices.addLink(LinkData)
      .then((response) => {
        if (response.data.success) {
          setAllLinks(response?.data?.data);
          fetchLinks()
          setOpen(false);
          setConfirm(true);
          setTimeout(() => {
            setConfirm(false);
          }, 2000);
        } else {
          setError(true);
          setErrorMessage("Something Went Wrong");
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
       setError(true);
       setErrorMessage("Something Went Wrong");
      });
    handleEmpty();
  };

  // Edit Link
  const handleEditLink = async (newLinkData, LinkId) => {
    setOpen(true)
    setLoading(true);
    await IndexServices.editLink(LinkId, newLinkData)
      .then((response) => {
        if (response.data.success) {
          setAllLinks(response?.data?.data);
          fetchLinks();
          setOpen(false);
          setConfirm(true);
          setTimeout(() => {
            setConfirm(false);
          }, 2000);
          
        } else {
          setError(true);
          setErrorMessage("Something Went Wrong");
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        setErrorMessage("Something Went Wrong");
      });
    handleEmpty(); 
  };

  const handleChangePlatform = (event) => {
    setSocialPlatform(event.target.value);
  };

  const handleEmpty = () => {
    setLink("");
    setSocialPlatform("");
  };

  const handleAddData = (data) => {
    setLink(extractUsernameFromUrl(data.link));
    setSocialPlatform(data.plateform);
  };

  // Delete Link
  const handleDeleteLink = async () => {
    setOpen(true)
    setLoading(true);
    await IndexServices.deleteLink(clickedLinkId, { role: storedRole })
      .then((response) => {
        if (response.data.success) {
          fetchLinks();
          setOpen(false);
          setConfirm(true);
          setTimeout(() => {
            setConfirm(false);
          }, 2000);
        } else {
         setError(true);
         setErrorMessage("Something Went Wrong");
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        setErrorMessage("Something Went Wrong");
      });
  };
  return (
    <SocialIconContext.Provider
      value={{
        handleAddLink,
        handleDeleteLink,
        Link,
        setLink,
        allLinks,
        setAllLinks,
        open,
        setOpen,
        confirm,
        setConfirm,
        handleChangePlatform,
        socialPlatform,
        setSocialPlatform,
        handleEditLink,
        SocialLinkArray,
        fetchLinks,
        loadingLinks,
        setLoadingLinks,
        openDel,
        setOpenDel,
        loading,
        setClickedLinkId,
        handleEmpty,
        handleAddData,
        editMode,
        setEditMode,
      }}
    >
      {children}
      <Loader open={open} close={setOpen} />
      <SuccessMessage open={confirm} close={setConfirm} />
      <ErrorMessage open={error} close={setError} message={errorMessage} />
    </SocialIconContext.Provider>
  );
}
