import React, { useState } from 'react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {PRIMARY_COLOR_DarkNavyBlue, PRIMARY_COLOR_MediumRoyalBlue} from "../constants/Colors";

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: PRIMARY_COLOR_MediumRoyalBlue,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

function NavItem({ item, active }) {

  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

    // const { callsData } = React.useContext(CallsContext);
    // const { orders } = React.useContext(OrdersContext);
    // const { emails } = React.useContext(EmailContext);


    const getCount = (listName) => {
        // const list = listName === 'Calls' ? callsData : listName === 'Emails' ? emails : listName === 'Orders' ? orders : [];
        // const field = listName === 'Calls' ? 'isOpened' : listName === 'Emails' ? 'isRead' : listName === 'Orders' ? 'isOpened' : null;
        // if (field === null)
        //     return '';
        // const count =  list.reduce((acc, item) => (item[field] ? acc : acc + 1), 0)
        // return count === 0 ? '' : ("(" + count + ")")
        return ''
    }

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };
  
  const activeRootStyle = {
    color: PRIMARY_COLOR_MediumRoyalBlue,
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(PRIMARY_COLOR_MediumRoyalBlue, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
      <>
        <ListItemStyle onClick={handleOpen} sx={{...(isActiveRoot && activeRootStyle)}}>
          <ListItemIconStyle sx={{...(isActiveRoot && activeRootStyle)}}>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
            {
                open ? <ArrowDownwardIcon /> : <ArrowForwardIosIcon/>
            }
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle)
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main'
                        })
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText
                      style={{color: getCount(title) === '' ? '' : PRIMARY_COLOR_DarkNavyBlue}}
                      disableTypography primary={title + " " +  getCount(title)}
                  />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
      <ListItemStyle
          component={RouterLink} to={path}
          sx={{...(isActiveRoot && activeRootStyle)}}
      >
        <ListItemIconStyle
            style={{
              color: isActiveRoot ?
                  PRIMARY_COLOR_MediumRoyalBlue
                  : theme.palette.text.secondary
            }}
        >
          {icon && icon}
        </ListItemIconStyle>
        <ListItemText
            style={{color: getCount(title) === '' ? '' : PRIMARY_COLOR_DarkNavyBlue}}
            disableTypography primary={title + " " + getCount(title)}
        />
        {info && info}
      </ListItemStyle>
  );
}


export default function NavSection({ navConfig, ...other }) {

  const { pathname } = useLocation()
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other}>
      <List disablePadding>
        {
          navConfig.map((item) => (
              <NavItem key={item.title} item={item} active={match} />
          ))
        }
      </List>
    </Box>
  );
}
