import http from "../utils/AxiosRequest";
import httpForm from "../utils/AxiosFormRequest";

class IndexService {
  addAdmin(data) {
    return httpForm.post("/addadmin", data);
  }

  getAdmins() {
    return http.get("/getadmins");
  }

  deleteAdmin(id, data) {
    return httpForm.delete(`/deleteadmin/?userId=${id}`, data);
  }

  login(data) {
    return http.post("/login", data);
  }

  getContacts() {
    return httpForm.get("/contact");
  }

  getJobs() {
    return httpForm.get("/jobs");
  }

  addJob(data) {
    return httpForm.post("/job", data);
  }

  deleteJob(jobId, data) {
    return httpForm.delete(`/job/?jobId=${jobId}`, data);
  }

  updateJob(editId, data) {
    return httpForm.put(`/job/?jobId=${editId}`, data);
  }

  getCandidates() {
    return httpForm.get("/candidates");
  }

  getLinks() {
    return http.get("/getlink");
  }
  addLink(data) {
    return httpForm.post("/addlink", data);
  }

  editLink(id, data) {
    return httpForm.put(`/editlink/?linkId=${id}`, data);
  }

  deleteLink(id, data) {
    return httpForm.delete(`/deletelink/?linkId=${id}`, data);
  }

  getDirectors() {
    return http.get("/directors");
  }
  addDirectors(data) {
    return httpForm.post("/directorteam", data);
  }

  editDirectors(id, data) {
    return httpForm.put(`/directorteam/?directorTeamId=${id}`, data);
  }

  deleteDirectors(id, role) {
    return httpForm.delete(
      `/directorteam/?directorTeamId=${id}&role=${role}`
    );
  }

  getLeadershipTeam() {
    return http.get("/leadershipteam");
  }
  addLeadershipTeam(data) {
    console.log("data in index",data)
    return httpForm.post("/leadershipteam", data);
  }

  editLeadershipTeam(id, data) {
    return httpForm.put(`/leadershipteam/?leadershipTeamId=${id}`, data);
  }

  deleteLeadershipTeam(id, role) {
    return httpForm.delete(
      `/leadershipteam/?leadershipTeamId=${id}&role=${role}`
    );
  }
  getLocalTeam() {
    return http.get("/localteam");
  }
  addLocalTeam(data) {
    return httpForm.post("/localteam", data);
  }

  editLocalTeam(id, data) {
    return httpForm.put(`/localteam/?localTeamId=${id}`, data);
  }

  deleteLocalTeam(id, role) {
    return httpForm.delete(`/localteam/?localTeamId=${id}&role=${role}`);
  }
}

export default new IndexService();
