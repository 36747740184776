import {Grid, Stack, Typography } from "@mui/material";
import React from "react";
import {getLocalReplaced} from "../../../utils/Functions";
import {PRIMARY_COLOR_LightSkyBlue} from "../../../constants/Colors";

const CandidateDetail = ({ item, filteredJob }) => {
  return (
    <Stack gap={4} ml={2}>
      <Grid container>
        <Grid xs={6}>
          <Stack>
            <Typography sx={{ fontWeight: "550" }} variant="h6">
              Name
            </Typography>
            <Typography>{item.name}</Typography>
          </Stack>
        </Grid>
        <Grid xs={6}>
          <Stack>
            <Typography sx={{ fontWeight: "550" }} variant="h6">
              Email
            </Typography>
            <Typography>{item.email}</Typography>
          </Stack>
        </Grid>
        <Grid xs={6} pt={2}>
          <Stack>
            <Typography sx={{ fontWeight: "550" }} variant="h6">
              Phone Number
            </Typography>
            <Typography>{item.phone}</Typography>
          </Stack>
        </Grid>
        <Grid xs={6} pt={2}>
          <Stack>
            <Typography sx={{ fontWeight: "550" }} variant="h6">
              Link
            </Typography>
            <a href={item.link}>{item.link}</a>
          </Stack>
        </Grid>
        <Grid xs={12} pt={2}>
          <Stack>
            <Typography sx={{ fontWeight: "550" }} variant="h6">
              About Candidate
            </Typography>
            <Typography>{item.about}</Typography>
          </Stack>
        </Grid>
        <Grid xs={4} pt={2}>
          <Stack>
            <Typography sx={{ fontWeight: "550" }} variant="h6">
              CV
            </Typography>
            <Typography
                variant={'body2'}
                sx={{
                  userSelect: 'none', cursor: 'pointer',
                  display: 'inline', padding: 1,
                  borderRadius: 30, border: `1px solid ${PRIMARY_COLOR_LightSkyBlue}`,
                  textAlign: 'center'
                }}
                onClick={() => {
                  const file = getLocalReplaced(item.cv);
                  window.open(file);
                }}
            >
              VIEW & DOWNLOAD
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={12}>
          <Typography sx={{ fontWeight: "550" }} variant="h5">
            Job
          </Typography>
        </Grid>
        <Grid xs={6}>
          <Stack pt={2}>
            <Typography sx={{ fontWeight: "550" }} variant="h6">
              Job Title
            </Typography>
            <Typography>{filteredJob?.jobName}</Typography>
          </Stack>
        </Grid>
        <Grid xs={6}>
          <Stack pt={1}>
            <Typography sx={{ fontWeight: "550" }} variant="h6">
              Experience
            </Typography>
            <Typography>{filteredJob?.experience}</Typography>
          </Stack>
        </Grid>
        <Grid xs={6}>
          <Stack pt={1}>
            <Typography sx={{ fontWeight: "550" }} variant="h6">
              Overview
            </Typography>
            <Typography>{filteredJob?.overview}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default CandidateDetail;
