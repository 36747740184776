import React, {useContext} from 'react';
import {
    BrowserRouter as Router, Navigate, Route, Routes
} from "react-router-dom";
import {AuthRoutes} from "../constants/RoutesList";
import ProtectedRoute from "./components/ProtectedRoute";
import {AuthenticationContext} from "../contexts/AuthContext";
import Login from "../pages/authentication/Login";

export default function Main() {

    const authenticationContext = useContext(AuthenticationContext)
    const {isAuthenticated} = authenticationContext

    return (
        <Router>
            <Routes>
                <Route path={'/'}
                       element={
                           isAuthenticated === false ?  <Login/> :
                               <Navigate to={'/contactus'}/>
                       }
                />
                <Route path={'/login'}
                       element={
                           isAuthenticated === false ?  <Login/> :
                               <Navigate to={'/contactus'}/>
                       }
                />
                {
                    AuthRoutes.map((value, index) => {
                        return (
                            <Route path={value.path} key={index}
                                   element={
                                       <ProtectedRoute>{value.component}</ProtectedRoute>
                                   }
                            />
                        )
                    })
                }
            </Routes>
        </Router>
    );
}
