import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import CustomAvatar from "../../../general_components/CustomAvatar";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { Typography } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";

const useStyles = makeStyles({
  image: {
    width: "100%",
    height: 150,
    minHeight: 150,
    borderRadius: 15,
    position: "absolute",
    backgroundColor: "#eee",
    objectFit: "cover",
    resizeMode: "contain",
  },
});

export default function AddMobileImage({
  mobileImage,
  setMobileImage,
  mobileRawImage,
  setMobileRawImage,
  mobileMultipleImages,
  setmobileMultipleImages,
  mobileMultipleRawImages,
  setMobileMultipleRawImages,
  isNewsFile,
  setIsNewsFile,
}) {
  const classes = useStyles();
    const theme = useTheme();
  const ref = React.useRef(null);
  const [load, setLoad] = useState(true);

  const getMobileFile = async (e1, name) => {
    var file = e1.target.files[0];
    if (file !== undefined) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        fileType: "JPEG",
      };

      try {
        var fr = new FileReader();
        fr.onload = function (e) {
          setMobileRawImage({
            image: e.target.result,
            file: new File([file], "image.jpeg"),
          });
          setMobileImage(e.target.result);
        };
        fr.readAsDataURL(file);
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <div>
      <Typography sx={{ color: theme.palette.text.secondary }}>
        Mobile Image
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: isNewsFile ? "center" : "flex-start",
        }}
      >
        <input
          ref={ref}
          style={{ display: "none" }}
          onChange={(e) => getMobileFile(e)}
          type={"file"}
        />
        <div style={{ position: "relative" }}>
          <CustomAvatar
            avatar={mobileImage}
            imageStyle={{
              width: isNewsFile ? 200 : 210,
              height: isNewsFile ? 160 : 180,
              minHeight: isNewsFile ? 160 : 150,
              borderRadius: 4,
              objectFit: "cover",
              cursor: "pointer",
            }}
            handleClick={() => ref.current.click()}
            alphaImageStyle={{ width: 100, height: 100, bgcolor: "#FFFFFF" }}
            child={<InsertPhotoIcon sx={{ fontSize: 60 }} />}
          />
        </div>
      </div>
    </div>
  );
}
