import Dialog from "./Dialog";
import Button from "./Button";
import Input from "./Input";

export default function ComponentsOverrides(theme) {
    return Object.assign(
        Dialog(theme),
        Input(theme),
        Button(theme)
    );
}
