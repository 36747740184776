
export default function Input(theme) {
    return {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderRadius: 10,
                        },
                        backgroundColor: '#fff',
                        color: '#333',
                        borderRadius: 10,
                    },
                    marginTop: 2,
                },
            },
        }
    };
}
