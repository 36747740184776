import {
  PRIMARY_COLOR_DarkNavyBlue, PRIMARY_COLOR_MediumRoyalBlue,
  SECONDARY_COLOR_DarkMaroon,
} from "../constants/Colors";


const palette = {
  type: "light",
  primary: {
    main: PRIMARY_COLOR_DarkNavyBlue,
  },
  secondary: {
    main: SECONDARY_COLOR_DarkMaroon,
  },
  transparent: {
    main: "transparent",
  },
  text: {
    secondary: 'rgba(102,135,135,0.54)',
  },
};

export default palette