import { Stack, Typography } from '@mui/material'
import React from 'react'

export default function StackCustom({text,children}){
    return(
        <Stack direction="row" alignItems="center">
            <Typography variant='h5' sx={{fontWeight: 700}}>
                {text}
            </Typography>
            {children}
        </Stack>
    )
}
