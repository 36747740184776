import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import {
  PRIMARY_COLOR_DarkNavyBlue,
  SECONDARY_COLOR_DarkMaroon,
} from "../../../constants/Colors";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  editButton: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#F3F4FF",
    },
  },
  highlighted: {
    backgroundColor: SECONDARY_COLOR_DarkMaroon,
  },
});

export default function CandidateList({ setShowDetails, candidates, filteredJob, setItem, jobId }) {

  const classes = useStyles();
  const [highlight, setHighlight] = useState(-1);

  console.log(filteredJob)

  return (
    <List
      sx={{
        maxWidth: "100%",
        minWidth: "100%",
        maxHeight: "80vh",
        overflowY: "auto",
      }}
    >
      {
        candidates.map((item, index) => {
          return item.jobId === jobId ? (
              <ListItem
                  button
                  className={classes.editButton}
                  sx={{
                    padding: 0,
                    paddingTop: '1vh', paddingBottom: '1vh',
                    paddingLeft: '1vh',
                    borderRadius: 5,
                    backgroundColor: highlight === item.candidateId ? "#F3F4FF" : null,
                  }}

                  onClick={() => {
                    setHighlight(item.candidateId);
                    setShowDetails(true);
                    setItem(item);
                  }}
              >
                <ListItemAvatar>
                  <Avatar
                      style={{
                        backgroundColor:
                            highlight === item.candidateId ? PRIMARY_COLOR_DarkNavyBlue : null,
                      }}
                  >
                    {index + 1}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                    sx={{
                      color:
                          highlight === item.candidateId ? PRIMARY_COLOR_DarkNavyBlue : null,
                      textOverflow: "ellipsis",
                    }}
                    primary={item.name}
                    secondary={filteredJob?.jobName}
                />
              </ListItem>
          ) : null;
        })
      }
    </List>
  );
}
