import React, { useEffect, useState } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AdminsContext } from "../../contexts/AdminsContext";
// import ConfirmationDialog from "../../general_components/ConfirmationDialog";
import AddAdmin from "./components/AddAdmin";
import { AuthenticationContext } from "../../contexts/AuthContext";
import Page from "../../general_components/Page";
import StackCustom from "../../general_components/StackCustom";
import AdminCard from "./components/AdminCard";
import { PRIMARY_COLOR_DarkNavyBlue, PRIMARY_COLOR_LightSkyBlue } from "../../constants/Colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import IconButton from "@mui/material/IconButton";
import Cookies from "js-cookie";

const useStyles = makeStyles({});

const storedRole = Cookies.get("role");
const storedEmail = Cookies.get("email");

export default function Admins() {
    const classes = useStyles();
    //Context
    const adminsContext = React.useContext(AdminsContext);
    const { admins, handleDelete, fetchAdmins,setSelectedAdminId, openDel, setOpenDel, load } = adminsContext;

    const [open, setOpen] = React.useState(false);

     useEffect(() => {
       fetchAdmins();
     }, []);
    return (
      <Page title="Blutech Consulting | Admins">
        <Container sx={{ marginBottom: 5 }}>
          <StackCustom
            text="Admins"
            children={
              <IconButton
                style={{
                  backgroundColor: PRIMARY_COLOR_DarkNavyBlue,
                  color: "#fff",
                  marginLeft: 6,
                }}
                variant={"contained"}
                onClick={() => setOpen(true)}
              >
                <AddRoundedIcon size={18} />
              </IconButton>
            }
          />
          <Grid container spacing={5} pt={4}>
            {admins?.map((item, index) => (
              <Grid item xs={12} sm={6} md={3} key={`${index}Admins`}>
                <AdminCard
                  data={item}
                  index={index}
                  setSelectedAdminId={setSelectedAdminId}
                  load={load}
                  openDel={openDel}
                  setOpenDel={setOpenDel}
                  handleDelete={handleDelete}
                  storedRole={storedRole}
                  storedEmail={storedEmail}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
        <AddAdmin open={open} setOpen={setOpen} />
      </Page>
    );
}
