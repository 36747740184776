export const // Primary palette of BTC
  PRIMARY_COLOR_DarkNavyBlue = "#4169E1",  // #000080
  PRIMARY_COLOR_MediumRoyalBlue = "#4169E1",
  PRIMARY_COLOR_LightSkyBlue = "#87CEEB",
  // Secondary Palette of BTC
  SECONDARY_COLOR_DarkMaroon = "#DC143C",
  SECONDARY_COLOR_MediumCrimsonRed = "#DC143C",
  SECONDARY_COLOR_LightCoralRed = "#FF6F61",
  // Normal Colors
  WHITE = "#FFF",
  WHITE_DIM = "#EEE",
  WHITE_T = "#ffffffAD",
  BLACK = "#000",
  DARK = "#333",
  GRAY = "#E0E0E0",
  GRAY_LIGHT = "rgb(242, 242, 242)",
  //Important Colors
  SUCCESS_COLOR = "#8ce99a",
  ERROR_COLOR = "#ffa8a8",
  WARNING_COLOR = "#FF9800",
  INFO_COLOR = "#a5d8ff",
  PROCESS_COLOR = "#BBDEFB",
  //
  BG_SHADOW = "#adadad90",
  BG_DRAWER_COLOR = "#eee",
  PRIMARY_TEXT_COLOR = "#3f9a1d"; 
