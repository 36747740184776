import React from "react";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { PRIMARY_COLOR_DarkNavyBlue } from "../../../constants/Colors";
import { makeStyles } from "@mui/styles";
import {getShortDate} from "../../../utils/Functions";

const useStyles = makeStyles({
  detailBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  detailInnerBox: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  },
  detailheadings: {
    fontWeight: "600",
  },
});

const ContactDetails = ({ filteredUser }) => {
  const classes = useStyles();
  console.log(filteredUser)
  return (
    <Stack gap="2rem" padding="1rem">
      <Box className={classes.detailBox}>
        <Box className={classes.detailInnerBox}>
          <Avatar style={{ backgroundColor: PRIMARY_COLOR_DarkNavyBlue }} />
          <Stack>
            <Typography sx={{ fontWeight: "600" }}>
              {filteredUser[0]?.name}
            </Typography>
            <Typography>{filteredUser[0]?.phone}</Typography>
          </Stack>
        </Box>
        <Typography>{getShortDate(filteredUser[0]?.createdAt)}</Typography>
      </Box>
      <Stack gap={4} mt={0.5}>
        <Box display={"flex"} gap={16}>
          <Stack>
            <Typography className={classes.detailheadings}>Email</Typography>
            <Typography>{filteredUser[0]?.email}</Typography>
          </Stack>
          <Stack>
            <Typography className={classes.detailheadings}>
              Company Name
            </Typography>
            <Typography>{filteredUser[0]?.company}</Typography>
          </Stack>
        </Box>
        <Box>
          <Stack>
            <Typography className={classes.detailheadings}>Message</Typography>
            <Typography>{filteredUser[0]?.message}</Typography>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};

export default ContactDetails;
